
import { useState, useEffect } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, Select,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {
    DataGrid,
} from 'mantine-data-grid';
import { IconCirclePlus, IconExclamationCircle, IconCalendar, IconArrowLeft } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';
import { useViewportSize, useMediaQuery } from '@mantine/hooks';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { useNavigate, useParams } from 'react-router-dom';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import TimeTable from '../../components/schedule/TimeTable';

import { formatDD_MM_YYYY,formatDD_MM_YYYY_hh_mm_A } from '../../utils/DateTime';

export default function ViewSchedule() {
    const { id } = useParams()
    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const { width } = useViewportSize();
    const matchSM = useMediaQuery('(min-width: 48em)');
    const matchLG = useMediaQuery('(min-width: 75em)');
    let currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()

    const [openingHours, setOpeningHours] = useState([])
    const [courts, setCourts] = useState([])
    const [schedule, setSchedule] = useState([])
    const [sports, setSports] = useState([])

    const [selectedSport, setSelectedSport] = useState(null);
    const [date, setDate] = useState(currentDate)

    const { isFetching, isError, } = useQuery(
        "venue-schedule",
        async ({ signal }) => (await axiosPrivate.post("/locations/read-by-location-id", {locationId: id, uid: '' }, { signal })).data.location,
        {
            initialData: null,
            onSuccess: (res) => {
                setOpeningHours(JSON.parse(res?.opening_hours ? res?.opening_hours : '[]'))
                setSelectedSport(res?.sports[0] ? res?.sports[0].value : 0)
                setSports(res?.sports)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getCourtDataMutation = useMutation(
        (data) => axiosPrivate.post("/crm/schedule/get-courts-schedule-from-location", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res)
                setCourts(res?.data?.courts)
                setSchedule(res?.data?.schedule)
                // console.log(res?.data?.schedule)
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );


    useEffect(()=>{
        if(selectedSport && date){
            getCourtData()
        }
    },[selectedSport, date])

    const getCourtData = () => {
        getCourtDataMutation.mutate({ locationId: id, type: selectedSport, date: date })
    }

    let sideNavWidth = 300;
    if(!matchLG && matchSM){
        sideNavWidth = 220;
    }
    else if(!matchLG && !matchSM){
        sideNavWidth = 0;
    }

    const goBack = () => {
        navigate('/', { replace: true })
    }


    const onClickAddSchedule = () => {
        navigate(`/venue/schedule/add/${id}`)
    }

    return (
        <>

            <Group position='apart'>
                <Group>
                    <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} size='xs'>
                        Back
                    </Button>
                    <Text size={'xl'} weight={600}> Schedule </Text>
                </Group>
                <Button onClick={onClickAddSchedule} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                    Add Schedule
                </Button>
            </Group>
            <Divider my="md" />
            <Group spacing={20}>
                <Group spacing={10}>
                    <Select
                        // w={140}
                        size='sm'
                        placeholder="Select sport"
                        data={sports}
                        value={selectedSport}
                        onChange={setSelectedSport}
                    />
                    <DatePickerInput
                        // w={140}
                        size='sm'
                        valueFormat="DD/MM/YYYY"
                        rightSection={<IconCalendar size="1.1rem" stroke={1.5} color='#BABABA'/>}
                        placeholder="Select date"
                        value={date}
                        onChange={setDate}
                        // minDate={currentDate}
                    />
                </Group>
                <Group spacing={8}>
                    <div style={{ width: 30, height: 20, border: '1px solid #F38500', backgroundColor: theme.colorScheme === 'light' ? '#FEE6CB': '#3d3731', borderRadius: 2 }}/>
                    <Text fw={500} lh={'normal'} size={12}>Booked</Text>
                </Group>
                <Group spacing={8}>
                    <div style={{ width: 30, height: 20, border: theme.colorScheme === 'light' ? '1px solid #D5D5D5' :'1px solid rgba(255, 255, 255, 0.3)', backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', borderRadius: 2 }}/>
                    <Text fw={500} lh={'normal'} size={12}>Available</Text>
                </Group>
            </Group>
            <TimeTable courts={courts} schedule={schedule} openingHours={openingHours} date={date} isLoading={getCourtDataMutation.isLoading || isFetching} isError={getCourtDataMutation.isError || isError} scrollWidth={width-(sideNavWidth+theme.spacing.md*2)}/>
            <Divider my="md" />
            <Text size={'xl'} weight={600} lh={"normal"} mb={'md'}>Schedule on ({formatDD_MM_YYYY(date)})</Text>
            {/* <Divider my="md" /> */}
            <DataGrid
                data={schedule}
                loading={getCourtDataMutation.isLoading}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                {
                    header: 'No.',
                    cell: (cell) => {
                    return <Text>{cell.row.index + 1}</Text>;
                    },
                    size: 60,
                },
                {
                    accessorKey: 'courts',
                    header: 'Courts',
                    size: 135,
                },
                {
                    accessorKey: 'startDatetime',
                    header: 'Start Time',
                    cell: (cell) => {
                        return <Text>{formatDD_MM_YYYY_hh_mm_A(cell.getValue())}</Text>;
                    },
                    size: 135,
                },
                {
                    accessorKey: 'endDatetime',
                    header: 'End Time',
                    cell: (cell) => {
                        return <Text>{formatDD_MM_YYYY_hh_mm_A(cell.getValue())}</Text>;
                    },
                    size: 135,
                },
                ]}
            />
        </>
    );
}