import React, { useState, useEffect } from 'react'
import { ScrollArea, Space, Text, Loader, useMantineTheme, } from '@mantine/core'
// import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { IconAlertCircle } from '@tabler/icons-react'

import { formatDOW, formath, getDaysDiff, formatm } from '../../utils/DateTime'

export default function TimeTable({ courts, schedule, openingHours, date, isLoading, isError, scrollWidth }) {
    // const { width } = useViewportSize()
    // const matches = useMediaQuery('(min-width: 48em)');
    const theme = useMantineTheme()

    const [isLoaded, setIsLoaded] = useState(false)

    const opening =  openingHours.find(x => x.day === formatDOW(date))

    const renderHours = () => {
        if(!opening) return null
        const hoursList = [];
        for(let i = opening?.open; i < opening?.close; i++){
            hoursList.push(<div key={i} style={{display: 'flex', width: 60, justifyContent: 'center', marginRight: 3}}>
                <Text fw={600} lh={'normal'} size={14}>{i >= 10 ? i : `0${i}`}:00</Text>
            </div>);
        }
        return hoursList;
    };

    const renderMarker = (court) => {
        let filteredSchedules = schedule.filter((sche) => sche.courtArr.includes(court.courtId))
        let marker = []
        if(filteredSchedules.length > 0){
            marker = filteredSchedules.map((fs)=>{ 
                let startMinute = parseInt(formatm(fs?.startDatetime))
                let endMinute = parseInt(formatm(fs?.endDatetime))
                let dayDiffEnd = getDaysDiff(date, fs?.endDatetime)
                let dayDiffStart = getDaysDiff(date, fs?.startDatetime)
                if(dayDiffStart > 0){    // from previous day
                    if(endMinute > 0)       // if end minute more than 0
                    return {start: 0, end: parseInt(formath(fs?.endDatetime))+1, startMinute: 0, endMinute}
                    else
                    return {start: 0, end: parseInt(formath(fs?.endDatetime)), startMinute: 0, endMinute}
                }
                else if(dayDiffEnd < 0) // to next day
                    return {start: parseInt(formath(fs?.startDatetime)), end: 24, startMinute, endMinute: 45}
                else{                       // same day
                    if(endMinute > 0)       // if end minute more than 0
                    return {start: parseInt(formath(fs?.startDatetime)), end: parseInt(formath(fs?.endDatetime))+1, startMinute, endMinute}
                    else
                    return {start: parseInt(formath(fs?.startDatetime)), end: parseInt(formath(fs?.endDatetime)), startMinute, endMinute}
                }
            })
            // console.log(marker)
        }
        if(!opening) return null
        const markerList = [];
        let markerIndex = 0;
        let start = null
        let end = null
        let startMinute = null
        let endMinute = null
        for(let i = opening?.open; i < opening?.close; i++){
            if(filteredSchedules.length > 0){
                if(marker[markerIndex].start === i){ 
                    start = marker[markerIndex].start
                    end = marker[markerIndex].end
                    startMinute = marker[markerIndex].startMinute
                    endMinute = marker[markerIndex].endMinute
                    if(markerIndex > 0){
                        if(marker[markerIndex-1].end-1 === i && marker[markerIndex-1].endMinute > 0){
                            startMinute = 0
                        }
                    }
                    if(filteredSchedules.length > markerIndex+1)
                        markerIndex++;
                }
            }
            if(i >= start && i < end){
                markerList.push(
                    // <div key={i} style={{height: 40, width: 60, border: '1px solid #F38500', backgroundColor: theme.colorScheme === 'light' ? '#FEE6CB': '#3d3731', borderRadius: 4, marginRight: 3}} />
                    <div key={i} style={{display: 'flex', marginRight: 3}}>
                        {i === start && startMinute >= 30 ?
                            <>
                            <div style={{height: 40, width: 30, border: theme.colorScheme === 'light' ? '1px solid #D5D5D5' :'1px solid rgba(255, 255, 255, 0.3)', backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', borderRightWidth: 0, borderTopLeftRadius: 4,borderBottomLeftRadius: 4,}}/>
                            <div style={{height: 40, width: 30, border: '1px solid #F38500', backgroundColor: theme.colorScheme === 'light' ? '#FEE6CB': '#3d3731', borderLeftWidth: 0, borderTopRightRadius: 4,borderBottomRightRadius: 4,}}/> 
                            </>
                        : 
                        i >= start && i === (end-1) && (endMinute > 0 && endMinute <= 30) ?
                            <>
                            <div style={{height: 40, width: 30, border: '1px solid #F38500', backgroundColor: theme.colorScheme === 'light' ? '#FEE6CB': '#3d3731', borderRightWidth: 0, borderTopLeftRadius: 4,borderBottomLeftRadius: 4,}}/>
                            <div style={{height: 40, width: 30, border: theme.colorScheme === 'light' ? '1px solid #D5D5D5' :'1px solid rgba(255, 255, 255, 0.3)', backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', borderLeftWidth: 0, borderTopRightRadius: 4,borderBottomRightRadius: 4,}}/> 
                            </>
                        :
                            <>
                                <div key={i} style={{height: 40, width: 60, border: '1px solid #F38500', backgroundColor: theme.colorScheme === 'light' ? '#FEE6CB': '#3d3731', borderRadius: 4,}} /> 
                            </>
                        }
                    </div>
                );
            }else {
                markerList.push(<div key={i} style={{height: 40, width: 60, border: theme.colorScheme === 'light' ? '1px solid #D5D5D5' :'1px solid rgba(255, 255, 255, 0.3)', backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', borderRadius: 4, marginRight: 3}} />);
            }   
        }
        return markerList;
    }

    const timeTableComponent = courts.map((item, i) => 
        (
            <div key={i} style={{display: 'flex', paddingTop: 5}}>
                <div style={{minWidth: 80, display: 'flex', alignItems: 'center'}}>
                    <Text fw={600} lh={'normal'} size={14}>{item?.name}</Text>
                </div>
                {renderMarker(item)}
            </div>
        )
    )

    useEffect(()=>{
        setIsLoaded(true)
    },[])

    if(isError) return (
        <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', flexDirection: 'column', height: 300, backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', justifyContent: 'center', alignItems: 'center',marginTop: 25}}>
            <IconAlertCircle size={30} color='#f56912'/>
            <Text size={16} fw={'500'} mt={2}>Unable to load...</Text>
        </div>
        </ScrollArea>
    )

    if(!isLoaded || isLoading) return (
        <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', flexDirection: 'column',height: 300, backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', justifyContent: 'center', alignItems: 'center',marginTop: 25}}>
            <Loader size={30}/>
            <Text size={16} fw={'500'} mt={2}>Loading...</Text>
        </div>
        </ScrollArea>
    )

  return (
    <ScrollArea w={scrollWidth} offsetScrollbars>
        <div style={{display: 'flex', paddingTop: 25}}>
            <Space w={80}/>
            {renderHours()}
        </div>
        <Space h={5}/>
        {timeTableComponent}
    </ScrollArea>
  )
}
