import { useState, useEffect } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, TextInput, Flex, Grid, Select, Image, FileButton, Radio, SimpleGrid, UnstyledButton, SegmentedControl, MultiSelect, Textarea, ActionIcon, NumberInput
} from '@mantine/core';
import { IconUpload, IconArrowLeft, IconCheck, IconExclamationCircle, IconPhotoPlus, IconCircleXFilled, IconPlus, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { MAX_GALLERY_IMAGES } from '../../utils/Constants';

export default function AddVenue() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const queryClient = useQueryClient();

    const [logoFile, setLogoFile] = useState(null);
    const [previewLogo, setPreviewLogo] = useState(null)

    const [galleryFile, setGalleryFile] = useState(null)
    const [galleryFiles, setGalleryFiles] = useState([])
    const [previewGallery, setPreviewGallery] = useState([])

    const [layoutFile, setLayoutFile] = useState(null);
    const [previewLayout, setPreviewLayout] = useState(null)

    const [pricing, setPricing] = useState('')
    const [pricingSportSelection, setPricingSportSelection] = useState([])
    const [pricingSport, setPricingSport] = useState(1)

    useEffect(() => {
        if (!logoFile) {
            setPreviewLogo(null)
            return
        }

        const objectUrl = URL.createObjectURL(logoFile)
        setPreviewLogo(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [logoFile])

    useEffect(() => {
        if (!galleryFile) {
            // setPreview(null)
            return
        }

        const objectUrl = URL.createObjectURL(galleryFile)
        setGalleryFiles((prev) => [...prev, galleryFile])
        setPreviewGallery((prev) => [...prev, objectUrl])

        return () => URL.revokeObjectURL(objectUrl)
    }, [galleryFile])

    useEffect(() => {
        if (!layoutFile) {
            setPreviewLayout(null)
            return
        }

        const objectUrl = URL.createObjectURL(layoutFile)
        setPreviewLayout(objectUrl)

        return () => URL.revokeObjectURL(objectUrl)
    }, [layoutFile])

    const onClickRemoveImage = (index) => {
        const copy = [...previewGallery];
        const copy2 = [...galleryFiles]
        copy.splice(index, 1)
        copy2.splice(index, 1)
        setPreviewGallery(copy)
        setGalleryFiles(copy2)
    }

    const form = useForm({
        initialValues: {
            name: '',
            google_embed: '',
            contact: '',
            prices: '',
            description: '',
            address: '',
            post_code: '',
            city: '',
            state: '',
            latitude: '',
            longitude: '',
            have_shower: '1',
            have_parking: '1',
            have_shop: '1',

            sports: [],
            pricing: [],
            admin_fees: 0,

            monday_open: null,
            monday_close: null,
            monday_is24: '0',
            tuesday_open: null,
            tuesday_close: null,
            tuesday_is24: '0',
            wednesday_open: null,
            wednesday_close: null,
            wednesday_is24: '0',
            thursday_open: null,
            thursday_close: null,
            thursday_is24: '0',
            friday_open: null,
            friday_close: null,
            friday_is24: '0',
            saturday_open: null,
            saturday_close: null,
            saturday_is24: '0',
            sunday_open: null,
            sunday_close: null,
            sunday_is24: '0',
        },

        // havent validate latest add on like opening hours, prices, description, have_shower, have_toilet, have_shop -gary 
        validate: {
            name: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            google_embed: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            contact: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : value.trim().length < 9 ? 'Invalid Phone Number' : !/^\d*$/.test(value.trim()) ? 'Invalid Phone Number' : null),
            //prices: 
            description: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            address: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            post_code: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            city: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            state: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            latitude: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),
            longitude: (value) => (value.trim().length < 1 ? 'Please fill in the empty field' : null),

            sports: (value) => (value.length <= 0 ? 'Please fill in the empty field' : null),

            pricing: (value) => (value.length <= 0 ? 'Please fill in the empty field' : null),

            monday_open: (value) => (value===null ? 'Please select time' : null),
            monday_close: (value) => (value===null ? 'Please select time' : null),
            tuesday_open: (value) => (value===null ? 'Please select time' : null),
            tuesday_close: (value) => (value===null ? 'Please select time' : null),
            wednesday_open: (value) => (value===null ? 'Please select time' : null),
            wednesday_close: (value) => (value===null ? 'Please select time' : null),
            thursday_open: (value) => (value===null ? 'Please select time' : null),
            thursday_close: (value) => (value===null ? 'Please select time' : null),
            friday_open: (value) => (value===null ? 'Please select time' : null),
            friday_close: (value) => (value===null ? 'Please select time' : null),
            saturday_open: (value) => (value===null ? 'Please select time' : null),
            saturday_close: (value) => (value===null ? 'Please select time' : null),
            sunday_open: (value) => (value===null ? 'Please select time' : null),
            sunday_close: (value) => (value===null ? 'Please select time' : null),
        },
    });

    useEffect(()=>{
        if(form.values.monday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, monday_open: 0, monday_close: 24 }))
        }
    },[form.values.monday_is24])
    useEffect(()=>{
        if(form.values.monday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, monday_close: null }))
        }
    }, [form.values.monday_open])
    useEffect(()=>{
        if(form.values.tuesday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, tuesday_open: 0, tuesday_close: 24 }))
        }
    },[form.values.tuesday_is24])
    useEffect(()=>{
        if(form.values.tuesday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, tuesday_close: null }))
        }
    }, [form.values.tuesday_open])
    useEffect(()=>{
        if(form.values.wednesday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, wednesday_open: 0, wednesday_close: 24 }))
        }
    },[form.values.wednesday_is24])
    useEffect(()=>{
        if(form.values.wednesday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, wednesday_close: null }))
        }
    }, [form.values.wednesday_open])
    useEffect(()=>{
        if(form.values.thursday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, thursday_open: 0, thursday_close: 24 }))
        }
    },[form.values.thursday_is24])
    useEffect(()=>{
        if(form.values.thursday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, thursday_close: null }))
        }
    }, [form.values.thursday_open])
    useEffect(()=>{
        if(form.values.friday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, friday_open: 0, friday_close: 24 }))
        }
    },[form.values.friday_is24])
    useEffect(()=>{
        if(form.values.friday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, friday_close: null }))
        }
    }, [form.values.friday_open])
    useEffect(()=>{
        if(form.values.saturday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, saturday_open: 0, saturday_close: 24 }))
        }
    },[form.values.saturday_is24])
    useEffect(()=>{
        if(form.values.saturday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, saturday_close: null }))
        }
    }, [form.values.saturday_open])
    useEffect(()=>{
        if(form.values.sunday_is24 === '1'){
            form.setValues((prev) => ({ ...prev, sunday_open: 0, sunday_close: 24 }))
        }
    },[form.values.sunday_is24])
    useEffect(()=>{
        if(form.values.sunday_is24 === '0'){
            form.setValues((prev) => ({ ...prev, sunday_close: null }))
        }
    }, [form.values.sunday_open])

    useEffect(()=>{
        if(form.values.sports.length > 0){
            let sportSelection = form.values.sports.map((x)=>{return {label: getSportLabel(x), value: x}})
            setPricingSportSelection(sportSelection)
            setPricingSport(form.values.sports[0])
        }
        else{
            setPricingSportSelection([])
        }
    }, [form.values.sports])

    //Assuming verifyImg = verify logo then havent verify gallery, layout - gary
    const verifyImg = () => {
        if (previewLogo === null) {
            notifications.show({
                id: 'venue-verify-img',
                loading: false,
                title: `Error!`,
                message: `Please provide venue logo`,
                autoClose: true,
            })
            return false
        }
        if (previewLayout === null) {
            notifications.show({
                id: 'venue-verify-img',
                loading: false,
                title: `Error!`,
                message: `Please provide venue layout`,
                autoClose: true,
            })
            return false
        }
        if (previewGallery.length === 0) {
            notifications.show({
                id: 'venue-verify-img',
                loading: false,
                title: `Error!`,
                message: `Please provide venue gallery`,
                autoClose: true,
            })
            return false
        }
        return true
    }

    const submitVenue = (data) => {
        // console.log(data)
        if (!verifyImg()) return

        let opening_hours = [
            {"day": "Monday", "open": data.monday_open, "close": data.monday_close, "isClose": false},
            {"day": "Tuesday", "open": data.tuesday_open, "close": data.tuesday_close, "isClose": false},
            {"day": "Wednesday", "open": data.wednesday_open, "close": data.wednesday_close, "isClose": false},
            {"day": "Thursday", "open": data.thursday_open, "close": data.thursday_close, "isClose": false},
            {"day": "Friday", "open": data.friday_open, "close": data.friday_close, "isClose": false},
            {"day": "Saturday", "open": data.saturday_open, "close": data.saturday_close, "isClose": false},
            {"day": "Sunday", "open": data.sunday_open, "close": data.sunday_close, "isClose": false}
        ]

        let sports = data?.sports.map((item)=>(`${item.toString()}`))
        // console.log(sports);
        // return;
        const formData = new FormData();

        formData.append('name', data?.name.trim());
        formData.append('contact', data?.contact.trim())
        formData.append('sports', JSON.stringify(sports))
        formData.append('opening_hours', JSON.stringify(opening_hours))
        // formData.append('prices', 5)
        formData.append('pricing', JSON.stringify(data?.pricing))
        formData.append('admin_fees', data?.admin_fees)
        formData.append('address', data?.address.trim())
        formData.append('city', data?.city.trim())
        formData.append('post_code', data?.post_code.trim())
        formData.append('state', data?.state.trim())
        formData.append('description', data?.description.trim())
        formData.append('latitude', data?.latitude.trim())
        formData.append('longitude', data?.longitude.trim())
        formData.append('have_shower', data?.have_shower === '1'?1:0)
        formData.append('have_parking', data?.have_parking === '1'?1:0)
        formData.append('have_shop', data?.have_shop === '1'?1:0)
        formData.append('google_embed', data?.google_embed.trim())

        formData.append('layout_img', layoutFile)
        formData.append('logo', logoFile)

        notifications.show({
            id: 'create-venue',
            loading: true,
            title: 'Creating venue',
            message: 'The venue is being created.',
            autoClose: false,
        })

        //console.log(formData)
        createvenueMutation.mutate(formData)
    }

    const uploadGallery = (locationId) => {
        const formData = new FormData();

        formData.append('locationId', locationId);

        [...galleryFiles].forEach((file, i) => {
            formData.append(`images`, file, file.name)
        })

        uploadGalleryMutation.mutate(formData)
    }

    const getSportSelectionQuery = useQuery(
        "getSportSelection",
        async ({ signal }) => (await axiosPrivate.get("/sports/get-all-selection", { }, { signal })).data.sports,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
                // setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const createvenueMutation = useMutation(
        (data) => axiosPrivate.post(`crm/locations/add`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: (res) => {
                let locationId = res.data.locationId
                notifications.update({
                    id: 'create-venue',
                    loading: true,
                    color: 'teal',
                    title: 'Uploading gallery',
                    message: 'The venue uploading is being uploaded.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                uploadGallery(locationId)
                queryClient.invalidateQueries("venues")
                // goBack()
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'create-venue',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const uploadGalleryMutation = useMutation(
        (data) => axiosPrivate.post(`crm/locations/add-images-to-gallery`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-venue',
                    color: 'teal',
                    title: 'Venue created',
                    message: 'The venue is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("venues")
                goBack()
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'create-venue',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred when uploading venue gallery.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 8000,
                });
                queryClient.invalidateQueries("venues")
                goBack()
            }
        }
    );

    const goBack = () => {
        navigate('/', { replace: true })
    }

    const addPricing = (type) => {
        if(pricing.trim().length < 1){
            notifications.show({
                title: 'Information',
                message: 'Please enter pricing description',
                color: 'blue',
            })
            return;
        }
        let tempPricing = form.values.pricing
        let index = tempPricing.findIndex(x => x.type === type);
        if(index >= 0){
            tempPricing[index].pricing.push(pricing)
            // console.log(tempPricing)
            // form.setFieldValue('pricing', [...form.values.pricing, {time: thursday.time, price: thursday.price}])
        }
        else{
            form.setFieldValue('pricing', [...form.values.pricing, {type: type, sport: getSportLabel(type), pricing: [pricing]}])
        }
        // let test = {"type":1,"sport":"Badminton","pricing":["RM20.00 per hour (Weekday)","RM30.00 per hour (Weekend)"]}
        setPricing('')
    }

    const removePricing = (index1, index2) => {
        console.log(index1 + ',' + index2)
        let tempPricing = form.values.pricing
        tempPricing[index1].pricing.splice(index2, 1)
        if(tempPricing[index1].pricing.length === 0){
            tempPricing.splice(index1, 1)
        }
        form.setFieldValue('pricing', tempPricing)
    }

    const getSportLabel = (value) => {
        let sport = getSportSelectionQuery.data.filter(obj => {return obj.value === value})
        return sport[0].label
    }

    const getPricingIndex = (type) => {
        let tempPricing = form.values.pricing
        let index = tempPricing.findIndex(x => x.type === type);
        return index
    }

    return (
        <>

            <Group position='left'>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={createvenueMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Create Venue </Text>
            </Group>
            <Divider my="md" />
            <form onSubmit={form.onSubmit((values) => { submitVenue(values); })}>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>

                        <Text fw={500}>Venue Logo<span style={{ color: 'red' }}>*</span></Text>
                        <Flex
                            gap="md"
                            justify="center"
                            align="center"
                            direction="column"
                            wrap="wrap"
                        >
                            <Image
                                width={'125'}
                                height={'125'}
                                src={previewLogo}
                                alt="With default placeholder"
                                withPlaceholder
                                fit='contain'
                            />
                            <FileButton onChange={setLogoFile} accept="image/png,image/jpeg" disabled={createvenueMutation.isLoading}>
                                {(props) => <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} {...props} leftIcon={<IconUpload size={18} />} w={'100%'}>Upload logo</Button>}
                            </FileButton>
                        </Flex>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Venue Name"
                            withAsterisk
                            {...form.getInputProps('name')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Google Map Embed Url"
                            withAsterisk
                            {...form.getInputProps('google_embed')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Contact Number"
                            withAsterisk
                            icon={<Text size={14} sx={(theme) => ({ color: theme.colorScheme === 'light' ? '#000' : '#FFF' })}>+60</Text>}
                            maxLength={10}
                            {...form.getInputProps('contact')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Textarea
                            label="Description"
                            placeholder="Enter venue description"
                            withAsterisk
                            autosize
                            minRows={4}
                            maxRows={8}
                            {...form.getInputProps('description')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <MultiSelect
                            data={getSportSelectionQuery.data}
                            withAsterisk
                            label="Select Sports"
                            placeholder="Select Sport"
                            {...form.getInputProps('sports')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <Radio.Group
                            name="haveFreeParking"
                            label="Have Free Parking?"
                            withAsterisk
                            {...form.getInputProps('have_parking')}
                            disabled={createvenueMutation.isLoading}
                        >
                            <Group mt="xs">
                                <Radio value='1' label="Yes" />
                                <Radio value='0' label="No" />

                            </Group>
                        </Radio.Group>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <Radio.Group
                            name="haveShowerRoom"
                            label="Have Shower Room?"
                            withAsterisk
                            {...form.getInputProps('have_shower')}
                            disabled={createvenueMutation.isLoading}
                        >
                            <Group mt="xs">
                                <Radio value='1' label="Yes" />
                                <Radio value='0' label="No" />

                            </Group>

                        </Radio.Group>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <Radio.Group
                            name="haveShop"
                            label="Have Shop?"
                            withAsterisk
                            {...form.getInputProps('have_shop')}
                            disabled={createvenueMutation.isLoading}
                        >
                            <Group mt="xs">
                                <Radio value='1' label="Yes" />
                                <Radio value='0' label="No" />

                            </Group>

                        </Radio.Group>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={10} md={10} lg={10} xl={6}>
                        <Text fw={500}>Court Gallery <span style={{ color: 'red' }}>*</span></Text>
                            <SimpleGrid cols={4} spacing="xs" verticalSpacing="xs">
                                {previewGallery.map((item, i)=>(
                                    <div key={i} style={{position: 'relative'}}>
                                    <Image
                                        key={i}
                                        width={'100%'}
                                        height={'125'}
                                        src={item}
                                        alt="With default placeholder"
                                        withPlaceholder
                                        fit='cover'
                                    />
                                        <UnstyledButton onClick={()=>onClickRemoveImage(i)} style={{position: 'absolute', top: -10, right: -10, cursor: 'pointer'}} disabled={createvenueMutation.isLoading}>
                                            <IconCircleXFilled size={20} style={{color: theme.colors.red[8]}}/>
                                        </UnstyledButton>
                                    </div>
                                ))}
                                {previewGallery.length < MAX_GALLERY_IMAGES ?
                                    <FileButton onChange={setGalleryFile} accept="image/png,image/jpeg" disabled={createvenueMutation.isLoading}>
                                        {(props) => <UnstyledButton {...props} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',width: '100%', height: 125, cursor: 'pointer', backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]}}>
                                            <IconPhotoPlus size={35} color={theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[6]}/>
                                            <Text size={12}>Click to add</Text>
                                        </UnstyledButton>}
                                    </FileButton>
                                : null}
                            </SimpleGrid>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>

                        <Text fw={500}>Layout <span style={{ color: 'red' }}>*</span></Text>
                        <Flex
                            gap="md"
                            justify="center"
                            align="center"
                            direction="column"
                            wrap="wrap"
                        >
                            <Image
                                width={'100%'}
                                height={'calc(40svh)'}
                                src={previewLayout}
                                alt="With default placeholder"
                                withPlaceholder
                                fit='cover'
                            />
                            <FileButton onChange={setLayoutFile} accept="image/png,image/jpeg" disabled={createvenueMutation.isLoading}>
                                {(props) => <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} {...props} leftIcon={<IconUpload size={18} />} w={'100%'}>Upload image</Button>}
                            </FileButton>
                        </Flex>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <Divider my="xs" label="Admin Fees" labelPosition="center" />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <NumberInput
                            label="Admin Fees"
                            {...form.getInputProps('admin_fees')}
                            precision={2}
                            hideControls
                            withAsterisk
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <Divider my="xs" label="Pricing Details" labelPosition="center" />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                        {form.values.sports.length > 0 ?
                        <>
                        {form.values.sports.map((item, i)=>(
                            <div key={i}>
                                <Text>{getSportLabel(item)}</Text>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                                {getPricingIndex(item) >=0 ?
                                <>
                                    {form.values.pricing[getPricingIndex(item)].pricing.map((item2, i2)=>(
                                        <div key={i2} style={{display: 'flex', gap: 10, width: '100%'}}>
                                            <div style={{flex: 1}}>
                                            <TextInput
                                                disabled={createvenueMutation.isLoading}
                                                value={item2}
                                                readOnly
                                            />
                                            </div>
                                            <ActionIcon onClick={() => removePricing(i,i2)} size='lg' variant="outline" color='red'>
                                                <IconX size={20} />
                                            </ActionIcon>
                                        </div>
                                    ))
                                    }
                                </>
                                : 
                                <div style={{display: 'flex', flexDirection: 'column',height: 36, backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2]: theme.colors.dark[6], justifyContent: 'center', alignItems: 'center',}}>
                                    <Text fw={600}>Please Enter Sport Pricing</Text>
                                </div>}
                                </div>
                            </div>
                        ))}
                        <div style={{display: 'flex', gap: 10, width: '100%'}}>
                            <div style={{flex: 1}}>
                            <Select
                                label="Sport"
                                placeholder="Pick one"
                                withAsterisk
                                data={pricingSportSelection}
                                value={pricingSport} onChange={setPricingSport}
                                disabled={createvenueMutation.isLoading}
                            />
                            </div>
                            <div style={{flex: 3}}>
                            <TextInput
                                label="Enter Pricing Details"
                                withAsterisk
                                disabled={createvenueMutation.isLoading}
                                value={pricing} 
                                onChange={(event) => setPricing(event.currentTarget.value)}
                            />
                            </div>
                            <ActionIcon onClick={() => addPricing(pricingSport)} size='lg' variant="outline" color='green' mt={26}>
                                <IconPlus size={20} />
                            </ActionIcon>
                        </div>
                        </>
                        : 
                        <div style={{display: 'flex', flexDirection: 'column',height: 36, backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[2]: theme.colors.dark[6], justifyContent: 'center', alignItems: 'center',}}>
                            <Text fw={600}>Please Select Sports First</Text>
                        </div>
                        }
                        </div>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <Divider my="xs" label="Opening Hours" labelPosition="center" />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Monday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('monday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.monday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.monday_open)].map((x,i)=>{let open = form.values.monday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('monday_close')}
                            disabled={createvenueMutation.isLoading || form.values.monday_open===null}
                            readOnly={form.values.monday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('monday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Tuesday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('tuesday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.tuesday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.tuesday_open)].map((x,i)=>{let open = form.values.tuesday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('tuesday_close')}
                            disabled={createvenueMutation.isLoading || form.values.tuesday_open===null}
                            readOnly={form.values.tuesday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('tuesday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Wednesday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('wednesday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.wednesday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.wednesday_open)].map((x,i)=>{let open = form.values.wednesday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('wednesday_close')}
                            disabled={createvenueMutation.isLoading || form.values.wednesday_open===null}
                            readOnly={form.values.wednesday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('wednesday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Thursday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('thursday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.thursday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.thursday_open)].map((x,i)=>{let open = form.values.thursday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('thursday_close')}
                            disabled={createvenueMutation.isLoading || form.values.thursday_open===null}
                            readOnly={form.values.thursday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('thursday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Friday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('friday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.friday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.friday_open)].map((x,i)=>{let open = form.values.friday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('friday_close')}
                            disabled={createvenueMutation.isLoading || form.values.friday_open===null}
                            readOnly={form.values.friday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('friday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Saturday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('saturday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.saturday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.saturday_open)].map((x,i)=>{let open = form.values.saturday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('saturday_close')}
                            disabled={createvenueMutation.isLoading || form.values.saturday_open===null}
                            readOnly={form.values.saturday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('saturday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid mb={0} pb={0}>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                        <Text fw={500} size={14}>Sunday <span style={{ color: 'red' }}>*</span></Text>
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Open"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24)].map((x,i)=>{return{value: i, label: i < 10 ? `0${i}:00` : `${i}:00`}})}
                            {...form.getInputProps('sunday_open')}
                            disabled={createvenueMutation.isLoading}
                            readOnly={form.values.sunday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Select
                            label="Close"
                            placeholder="Pick one"
                            withAsterisk
                            data={[...Array(24-form.values.sunday_open)].map((x,i)=>{let open = form.values.sunday_open+1; return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})}
                            {...form.getInputProps('sunday_close')}
                            disabled={createvenueMutation.isLoading || form.values.sunday_open===null}
                            readOnly={form.values.sunday_is24 === '1'}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={2} lg={2} xl={2}>
                        <Text fw={500} size={14}>24 Hours?</Text>
                        <SegmentedControl
                            {...form.getInputProps('sunday_is24')}
                            data={[
                                { label: 'No', value: '0' },
                                { label: 'Yes', value: '1' },
                            ]}
                            color="iCourtOrange"
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <Divider my="xs" label="Address" labelPosition="center" />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Address"
                            withAsterisk
                            {...form.getInputProps('address')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Post Code"
                            withAsterisk
                            {...form.getInputProps('post_code')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <TextInput
                            label="City"
                            withAsterisk
                            {...form.getInputProps('city')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <Select
                            label="State"
                            placeholder="Pick one"
                            withAsterisk
                            data={[
                                { value: 'Johor', label: 'Johor' },
                                { value: 'Kedah', label: 'Kedah' },
                                { value: 'Kelantan', label: 'Kelantan' },
                                { value: 'Kuala Lumpur', label: 'Kuala Lumpur' },
                                { value: 'Labuan', label: 'Labuan' },
                                { value: 'Malacca', label: 'Malacca' },
                                { value: 'Negeri Sembilan', label: 'Negeri Sembilan' },
                                { value: 'Pahang', label: 'Pahang' },
                                { value: 'Penang', label: 'Penang' },
                                { value: 'Perak', label: 'Perak' },
                                { value: 'Perlis', label: 'Perlis' },
                                { value: 'Putrajaya', label: 'Putrajaya' },
                                { value: 'Sabah', label: 'Sabah' },
                                { value: 'Sarawak', label: 'Sarawak' },
                                { value: 'Selangor', label: 'Selangor' },
                                { value: 'Terengganu', label: 'Terengganu' },
                            ]}
                            {...form.getInputProps('state')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <TextInput
                            label="Latitude"
                            withAsterisk
                            {...form.getInputProps('latitude')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3}>
                        <TextInput
                            label="Longitude"
                            withAsterisk
                            {...form.getInputProps('longitude')}
                            disabled={createvenueMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>

                <Grid my='xl'>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button color="gray" fullWidth onClick={goBack} disabled={createvenueMutation.isLoading}>
                            Back
                        </Button>
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button type="submit" fullWidth loading={createvenueMutation.isLoading}>
                            Submit
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
        </>
    );
}
