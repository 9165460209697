import Unauthorized from "../misc/Unauthorized";
import ManageVenue from "../venue/ManageVenue";
import LAViewVenue from "../venue/LAViewVenue";
import LAViewSchedule from "../schedule/LAViewSchedule";

import useAuth from "../../hooks/useAuth";

import { SUPER_ADMIN, LOCATION_ADMIN, LOCATION_STAFF } from "../../utils/Constants";

const Dashboard = () => {
    const { auth } = useAuth();
    return (
        auth?.role === SUPER_ADMIN
            ? <ManageVenue />
        : auth?.role === LOCATION_ADMIN || auth?.role === LOCATION_STAFF
            ? <LAViewSchedule />
            : <Unauthorized />
    )
}

export default Dashboard;