import { useState, } from 'react';
import {
  Text, Divider, Button, Group, useMantineTheme,
} from '@mantine/core';
import {
  DataGrid,
} from 'mantine-data-grid';
import { IconCirclePlus, IconPencil, IconSoccerField, IconCheck, IconExclamationCircle, IconArrowBack, IconEyeOff, IconCalendarMonth, IconUsersGroup, IconUsers } from '@tabler/icons-react';
import { notifications, } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ManageVenue() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const [actionId, setActionId] = useState('');

    const [openCModal, setOpenCModal] = useState(false); //delete
    const [openRCModal, setOpenRCModal] = useState(false); //restore
//   const [openRPCModal, setOpenRPCModal] = useState(false); //remove permanent


    const { isFetching, data: venues } = useQuery(
        "venues",
        async ({ signal }) => (await axiosPrivate.get(`crm/locations/read`, {}, { signal })).data.location,
        {
        initialData: [],
        onError: () => {
            notifications.show({
                id: 'error',
                title: `Error`,
                message: `Unable to load data`,
            })
        }
        }
    );


    const disableVenueMutation = useMutation(
        (locationId) => axiosPrivate.post(`crm/locations/enable-disable`, { locationId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'disable-venue',
                color: 'teal',
                title: `Venue disabled`,
                message: `The venue is successfully disabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("venues")
        },
        onError: () => {
            notifications.update({
                id: 'disable-venue',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const restoreVenueMutation = useMutation(
        (locationId) => axiosPrivate.post(`crm/locations/enable-disable`, { locationId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'restore-venue',
                color: 'teal',
                title: `Venue enabled`,
                message: `The venue is successfully enabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("venues")
        },
        onError: () => {
            notifications.update({
                id: 'restore-venue',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const disableVenue = () => {
        setOpenCModal(false);
        notifications.show({
            id: 'disable-venue',
            loading: true,
            title: `Venue disabling`,
            message: `The venue is being disable.`,
            autoClose: false,
        })
        disableVenueMutation.mutate(actionId)
    }

    const restoreVenue = () => {
        setOpenRCModal(false);
        notifications.show({
            id: 'restore-venue',
            loading: true,
            title: `venue restoring`,
            message: `The venue is being restored.`,
            autoClose: false,
        })
        restoreVenueMutation.mutate(actionId)
    }

//   const deletePermanentvenue = () => {
//     setOpenRPCModal(false)
//     showNotification({
//       id: 'delete-venue',
//       loading: true,
//       title: `venue removing`,
//       message: `The venue is being removed.`,
//       autoClose: false,
//       disallowClose: true,
//     })
//     deletePermanentvenueMutation.mutate(actionId)
//   }

  const onClickCreatevenue = () => {
    navigate("/venue/add");
  }

  return (
    <>
      {/* <EditCustomerModal data={customerId} opened={openECModal} onClose={() => setOpenECModal(false)} text="Are you sure you want to update this record?" onCustomerEdit={updateCustomer} 
        isLoading={updateCustomerMutation.isLoading} isSuccess={updateCustomerMutation.isSuccess} isError={updateCustomerMutation.isError} /> */}
      <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to disable it?" onClick={disableVenue} />
      <ConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} text="Are you sure you want to enable it?" onClick={restoreVenue} />
      {/* <ConfirmationModal opened={openRPCModal} onClose={() => setOpenRPCModal(false)} text="Are you sure you want to permanently remove it?" onClick={deletePermanentvenue} /> */}

      <Group position='apart'>
            <Text size={'xl'} weight={600}> Manage Venue </Text>
            <Button onClick={onClickCreatevenue} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                Add Venue
            </Button>
      </Group>
      <Divider my="md" />
      <DataGrid
        data={venues}
        loading={isFetching}
        striped
        highlightOnHover
        withGlobalFilter
        withPagination
        //withColumnFilters
        withSorting
        //withColumnResizing
        columns={[
          {
            header: 'No.',
            cell: (cell) => {
              return <Text>{cell.row.index + 1}</Text>;
            },
            size: 30,
          },
          {
            accessorKey: 'name',
            header: 'Name',
            size: 120,
          },
          {
            accessorKey: 'city',
            header: 'City',
            size: 70,
          },
          {
            accessorKey: 'state',
            header: 'State',
            size: 60,
          },
          {
            accessorKey: 'contact',
            header: 'Contact Number',
            size: 70,
            cell: (cell) => {
              return <Text>+60 {cell.getValue()}</Text>;
            },
          },
          {
            header: 'Action',
            cell: (cell) => {
              return <Group position="center" spacing={'xs'}>
                {cell.row.original.deleted ?
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconCalendarMonth size={18} />} size="xs" color="violet" component={Link} to={`/venue/schedule/${cell.row.original.locationId}`}>Schedule</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconUsersGroup size={18} />} size="xs" color="gray" component={Link} to={`/venue/membership/${cell.row.original.locationId}`}>Membership</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconSoccerField size={18} />} size="xs" color="blue" component={Link} to={`/venue/court/${cell.row.original.locationId}`}>Court</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconUsers size={18} />} size="xs" color="orange" component={Link} to={`/venue/admin/${cell.row.original.locationId}`}>Admin</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/venue/edit/${cell.row.original.locationId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.locationId); setOpenRCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Enable</Button>
                    {/* <Button onClick={() => { setActionId(cell.row.original.locationId); setOpenRPCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconTrashX size={18} />} size="xs" color="red">Permanently Delete</Button> */}
                  </>
                  :
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconCalendarMonth size={18} />} size="xs" color="violet" component={Link} to={`/venue/schedule/${cell.row.original.locationId}`}>Schedule</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconUsersGroup size={18} />} size="xs" color="gray" component={Link} to={`/venue/membership/${cell.row.original.locationId}`}>Membership</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconSoccerField size={18} />} size="xs" color="blue" component={Link} to={`/venue/court/${cell.row.original.locationId}`}>Court</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconUsers size={18} />} size="xs" color="orange" component={Link} to={`/venue/admin/${cell.row.original.locationId}`}>Admin</Button>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/venue/edit/${cell.row.original.locationId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.locationId); setOpenCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconEyeOff size={18} />} size="xs" color="red">Disable</Button>
                  </>
                }

              </Group>;
            },
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            size: 280,
          },
        ]}
      />
    </>
  );
}
