import moment from 'moment';
import momenttz from 'moment-timezone';

import { DAY_OF_WEEK } from './Constants';

export const getCurrentDateTime = () => {
    let date = moment().tz("Asia/Kuala_Lumpur")
    return moment(date).toDate()
}

//Format eg. 31/12/2023 04:48 PM
export const formatDD_MM_YYYY_hh_mm_A = (date) => {
    if(!date) return '-- --- ----';
    // let datetz = moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY hh:mm A')
}

//October 31, Tuesday 
export const formatMMMM_DD_DOW = (date) => {
    if(!date) return '----- --, ------';
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    let datetz = moment(date)
    const dow = DAY_OF_WEEK[datetz.day()];
    const momentDate = moment(datetz, "YYYY-MM-DD HH:mm:ss").format('MMMM DD, ')
    return momentDate + dow
}

//8:00 p.m
export const formath_mm_a = (date) => {
    if(!date) return '-- --- ----';
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('h:mm a')
}

//Format eg. 28/01/2023
export const formatDD_MM_YYYY = (date) => {
    if(!date) return '--/--/----';
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('DD/MM/YYYY')
}

//Tuesday 
export const formatDOW = (date) => {
    if(!date) return '----- --, ------';
    let datetz = moment(date).tz("Asia/Kuala_Lumpur")
    const dow = DAY_OF_WEEK[datetz.day()];
    return dow
}

//Webnesday
export const formatDOW_NextDay = (date) => {
    if(!date) return '----- --, ------';
    let datetz = moment(date).add(1, 'd').tz("Asia/Kuala_Lumpur")
    const dow = DAY_OF_WEEK[datetz.day()];
    return dow
}

//8 - hour
export const formath = (date) => {
    if(!date) return null;
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('H')
}

//8 - minute
export const formatm = (date) => {
    if(!date) return null;
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "YYYY-MM-DD HH:mm:ss").format('m')
}

//8 - minute
export const formatm_from_time = (date) => {
    if(!date) return null;
    // let datetz = Moment(date).tz("Asia/Kuala_Lumpur")
    return moment(date, "HH:mm").format('m')
}

export const getDaysDiff = (date1, date2) => {
    let dateStart = moment(date1, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
    let dateEnd = moment(date2, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD')
    let startDate = moment(`${dateStart}T00:00:00.000Z`);
    let endDate = moment(`${dateEnd}T00:00:00.000Z`);
    return startDate.diff(endDate, 'days')
}