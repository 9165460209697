
import { useState, useEffect } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, TextInput,
} from '@mantine/core';
import {
    DataGrid,
} from 'mantine-data-grid';
import { IconCirclePlus, IconExclamationCircle, IconCheck, } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';

import AddSportsModal from '../../components/sports/AddSportsModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ManageSport() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();

    const [openSModal, setOpenSModal] = useState(false);

    const { isLoading, isFetching, isError, error, data, } = useQuery(
        "sports",
        async ({ signal }) => (await axiosPrivate.get(`crm/sports/read`, {}, { signal })).data.sports,
        {
            initialData: [],
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            }
        },

    );

    const createSportMutation = useMutation(
        (data) => axiosPrivate.post(`crm/sports/add`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-sports',
                    color: 'teal',
                    title: 'Admin created',
                    message: 'The sports account is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("sports")
            },
            onError: () => {
                notifications.update({
                    id: 'create-sports',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );


    function createSport(data) {
        setOpenSModal(false);
        // console.log(data)
        notifications.show({
            id: 'create-sports',
            loading: true,
            title: 'Admin creating',
            message: 'The sports account is being created.',
            autoClose: false,
        })
        createSportMutation.mutate({ ...data })
    }

    return (
        <>
            {/* Add Sport Modal */}
            <AddSportsModal opened={openSModal} onClose={() => setOpenSModal(false)} onSubmit={createSport}
                isLoading={createSportMutation.isLoading} isSuccess={createSportMutation.isSuccess} isError={createSportMutation.isError} />

            <Group position='apart'>
                <Text size={'xl'} weight={600}> Manage Sports </Text>
                <Button onClick={() => setOpenSModal(true)} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                    Create Sport
                </Button>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        accessorKey: 'sports_name',
                        header: 'Sport',
                        size: 150,
                    }
                ]}
            />
        </>
    );
}