import { useEffect, useState, forwardRef } from 'react';
import {
    useMantineTheme, Button, Center, Text, Modal, Stack, Textarea, Select, Group, Flex, Avatar, TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconExclamationCircle, IconMail } from '@tabler/icons-react';

const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {image}
          <div>
            <Text size="md">{label}</Text>
          </div>
        </Group>
      </div>
    )
);

export default function EditNotificationModal({ opened, onClose, onEdit, data }) {
    const theme = useMantineTheme();
    
    useEffect(()=> {
        if(data != null){
            form.setValues({ type: data.type, title: data.title, body: data.body });
        }
    }, [data]);

    const form = useForm({
        initialValues: { type: '', title: '', body: ''},

        validate: {
            type: (value) => (value.length < 1 ? 'Please choose a notification type' : null),
            title: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
            body: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
        },
    });

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            radius='lg'
            // p='xl'
            zIndex={999}
        >
            <Center>
                <Text size='xl' weight={700} color={theme.colors.gray[6]} > Edit Notification </Text>
            </Center>
            <form onSubmit={form.onSubmit((values) => onEdit({notification_id: data.notification_id, ...values}))}>
                <Stack p='md'>
                {/* <Select
                        label="Notification Type"
                        placeholder="Pick One"
                        itemComponent={SelectItem}
                        maxDropdownHeight={400}
                        data={[
                            { image: <IconExclamationCircle/>, label: 'Alert', value: 'alert',},
                            { image: <IconMail/>, label: 'Message', value: 'message', },
                        ]}
                        {...form.getInputProps('type')}
                    /> */}
                    <TextInput 
                        label="Title" 
                        {...form.getInputProps('title')}/>
                    <Textarea
                        autosize
                        minRows={2}
                        label="Message Body" 
                        {...form.getInputProps('body')}/>
                </Stack>

                <Flex justify="flex-start"
                            align="center"
                            direction="row"
                            wrap="wrap" 
                            gap="md" p='md'>
                    <Button type="submit" sx={{flex: 1}}>
                        Update
                    </Button>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose() }}>
                        Cancel
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}
