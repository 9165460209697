
import { useState, useEffect } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, TextInput,
} from '@mantine/core';
import {
    DataGrid,
} from 'mantine-data-grid';
import { IconTrash, IconPencil, IconSearch, IconCirclePlus, IconExclamationCircle, IconCheck, IconArrowBack, IconArrowLeft } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import RevertConfirmationModal from '../../components/general/RevertConfirmationModal';
import AddAdminModal from '../../components/admin/AddAdminModal';
import EditAdminModal from '../../components/admin/EditAdminModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';
import { useNavigate, useParams } from 'react-router-dom';

export default function ManageLocationAdmin() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();
    const { auth } = useAuth()
    const { id } = useParams();
    const navigate = useNavigate()
    // console.log(id)

    const [openCModal, setOpenCModal] = useState(false);
    const [openRCModal, setOpenRCModal] = useState(false);
    const [openAModal, setOpenAModal] = useState(false);
    const [openEAModal, setOpenEAModal] = useState(false);

    const [deleteId, setDeleteId] = useState('');
    const [adminId, setAdminId] = useState([]);
    const [adminStatus, setAdminStatus] = useState("delet");

    const { isLoading, isFetching, isError, error, data, } = useQuery(
        "location-admins",
        async ({ signal }) => (await axiosPrivate.post(`crm/admin-location/read`, { locationId: id }, { signal })).data.admins,
        {
            initialData: [],
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: errMsg ? errMsg : `Unable to load data`,
                })
            }
        },

    );

    const createAdminMutation = useMutation(
        (data) => axiosPrivate.post(`crm/admin-location/create`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'create-admin',
                    color: 'teal',
                    title: 'Admin created',
                    message: 'The admin account is successfully created.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("location-admins")
            },
            onError: () => {
                notifications.update({
                    id: 'create-admin',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const updateAdminMutation = useMutation(

        (data) => axiosPrivate.post(`crm/admin-location/update`, data),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'update-admin',
                    color: 'teal',
                    title: 'Admin updated',
                    message: 'The admin account is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("location-admins")

            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'update-admin',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const deleteAdminMutation = useMutation(
        (admin_id) => axiosPrivate.post(`crm/admin-location/delete`, {locationId: id, admin_id}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'delete-admin',
                    color: 'teal',
                    title: `Admin ${adminStatus}ed`,
                    message: `The admin account is successfully ${adminStatus}ed.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("location-admins")
            },
            onError: () => {
                notifications.update({
                    id: 'delete-admin',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const deleteAdminMessage = () => {
        // console.log('delete: ' + deleteId);
        setOpenCModal(false);
        setOpenRCModal(false);
        notifications.show({
            id: 'delete-admin',
            loading: true,
            title: `Admin ${adminStatus}ing`,
            message: `The admin account is being ${adminStatus}ed.`,
            autoClose: false,
        })
        deleteAdminMutation.mutate(deleteId)
    }

    function createAdmin(data) {
        setOpenAModal(false);
        notifications.show({
            id: 'create-admin',
            loading: true,
            title: 'Admin creating',
            message: 'The admin account is being created.',
            autoClose: false,
        })
        createAdminMutation.mutate({locationId: id, ...data})
    }

    function updateAdmin(data) {
        setOpenEAModal(false);
        notifications.show({
            id: 'update-admin',
            loading: true,
            title: 'Updating Admin Account...',
            message: 'The admin account is being updated.',
            autoClose: false,
        })
        updateAdminMutation.mutate({locationId: id, ...data})
    }

    const goBack = () => {
        navigate('/', { replace: true })
      }

    return (
        <>
            {/* Edit Admin Modal */}
            <EditAdminModal data={adminId} opened={openEAModal} onClose={() => setOpenEAModal(false)} onUpdate={updateAdmin}
                isLoading={updateAdminMutation.isLoading} isSuccess={updateAdminMutation.isSuccess} isError={updateAdminMutation.isError} />
            {/* Add Admin Modal */}
            <AddAdminModal opened={openAModal} onClose={() => setOpenAModal(false)} onSubmit={createAdmin}
                isLoading={createAdminMutation.isLoading} isSuccess={createAdminMutation.isSuccess} isError={createAdminMutation.isError} />
            {/* Delete Confirmation Modal */}
            <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to delete it?" onClick={deleteAdminMessage} />
            <RevertConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} onClick={deleteAdminMessage} />

            <Group position='apart'>
                <Group>
                    <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={createAdminMutation.isLoading || updateAdminMutation.isLoading || deleteAdminMutation.isLoading} size='xs'>
                        Back
                    </Button>
                    <Text size={'xl'} weight={600}> Manage Venue Admin </Text>
                </Group>
                <Button onClick={() => setOpenAModal(true)} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                    Create Admin
                </Button>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        accessorKey: 'fullname',
                        header: 'Name',
                        size: 150,
                    },
                    {
                        accessorKey: 'email',
                        header: 'Email',
                        size: 200,
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                {cell.row.original.deleted ?
                                    <>
                                        <Button onClick={() => { setAdminId(cell.row.original); setOpenEAModal(true) }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" disabled >Edit</Button>
                                        <Button onClick={() => { setDeleteId(cell.row.original.uid); setOpenRCModal(true); setAdminStatus("restor") }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Restore</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => { setAdminId(cell.row.original); setOpenEAModal(true) }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" >Edit</Button>
                                        <Button disabled={auth.admin_id === cell.row.original.admin_id} onClick={() => { setDeleteId(cell.row.original.admin_id); setOpenCModal(true); setAdminStatus("delet") }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconTrash size={18} />} size="xs" color="red">Delete</Button>
                                    </>
                                }
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 265,
                    },
                ]}
            />
        </>
    );
}