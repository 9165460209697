import { useEffect, useRef } from 'react';
import {
    useMantineTheme, Button, Flex, TextInput, Center, Text, Modal, Stack, PasswordInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';

export default function NewAdminModal({ opened, onClose, onSubmit, isSuccess }) {
    const theme = useMantineTheme();
    const resetRef = useRef(null);

    useEffect(() => {
        if (isSuccess || onClose) {
            form.reset()
        }
    }, [isSuccess]);

    const form = useForm({
        initialValues: { fullname: '', email: '', password: '', confirmPassword: '' },

        validate: {
            fullname: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            email: (value) => (value.length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
            // role: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
            confirmPassword: (value, values) =>
                value !== values.password ? 'Passwords did not match' : null,
        },
    });

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            // p='xl'
            style={{ zIndex: 999 }}
        >
            <Center>
                <Text size='xl' weight={700} color={theme.colors.gray[6]}> Create Admin </Text>
            </Center>
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values, isAdmin: 1 }))}>
                <Stack p='md'>
                    <TextInput
                        label="Name"
                        {...form.getInputProps('fullname')}
                    />
                    <TextInput
                        label="Email"
                        {...form.getInputProps('email')}
                    />
                    {/* <TextInput
                        label="Role"
                        description="Eg: admin"
                        {...form.getInputProps('role')}
                        readOnly
                    /> */}
                    <PasswordInput
                        label="Password"
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        label="Re-enter Password"
                        {...form.getInputProps('confirmPassword')}
                    />
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" p='md'>
                    <Button type="submit" sx={{ flex: 1 }}>
                        Submit
                    </Button>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }}>
                        Cancel
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}