import { Group, Code, Header, MediaQuery, Burger, Image, useMantineTheme, ActionIcon, Tooltip, Text } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

// import MenuLogo from '../../assets/MenuLogo.svg'
// import LogoLight from '../../assets/LogoLight.png'
// import LogoDark from '../../assets/LogoDark.png'
import ColorSchemeToggle from './ColorSchemeToggle';

import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';


export default function TopHeader({ setOpened, opened }) {
const { auth } = useAuth();
  const theme = useMantineTheme();
    const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate('/login');
}

  return (
    <Header height={60} p="md" style={{ backgroundColor: theme.colorScheme === 'dark' && theme.colors.dark[8] }}>
      <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
        <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
          <Burger
            opened={opened}
            onClick={() => setOpened((o) => !o)}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <Group style={{ display: 'flex', width: '100%' }} position="apart">
          <Group position='center'>
            {/* <Image width={128} src={theme.colorScheme === 'dark' ? LogoDark : LogoLight} alt="XTENZ Warranty"/> */}
            <Text color='iCourtOrange' fw={'bold'} size={22}>I-Court</Text>
          </Group>
          <Group>
            <Code style={{ fontWeight: 700 }}>{auth?.fullname}</Code>
            <ColorSchemeToggle />
            <Tooltip label="Logout" color="iCourtOrange">
                <ActionIcon
                    onClick={() => signOut()}
                    size="lg"
                    sx={(theme) => ({
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                        color: theme.colorScheme === 'dark' ? theme.colors.red[6] : theme.colors.red[6],
                    })}
                >
                    <IconLogout size={18} />
                </ActionIcon>
            </Tooltip>
          </Group>
        </Group>
      </div>
    </Header>
  );
}