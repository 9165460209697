// export const BASE_API_URL = 'http://localhost:1337/api'
export const BASE_API_URL = 'https://icourt-admin.link/api'


export const MAX_GALLERY_IMAGES = 8

export const DAY_OF_WEEK = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
]

export const SUPER_ADMIN = 'SUPER_ADMIN'
export const LOCATION_ADMIN = 'LOCATION_ADMIN'
export const LOCATION_STAFF = 'LOCATION_STAFF'