import { useEffect, useState, forwardRef } from 'react';
import { useForm } from '@mantine/form'
import {
    useMantineTheme, Button, Center, Text, Modal, Stack, Textarea, Select, Group, Flex, Avatar, TextInput
} from '@mantine/core';
import { IconExclamationCircle, IconMail } from '@tabler/icons-react';

const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          {image}
          <div>
            <Text size="md">{label}</Text>
          </div>
        </Group>
      </div>
    )
);

export default function CreateNotificationModal({ opened, onClose, onCreate, isSuccess }) {
    const theme = useMantineTheme();

    useEffect(() => {
        if (isSuccess || onClose) {
            form.reset()
        }
    }, [isSuccess, onClose]);

    const form = useForm({
        initialValues: { 
            // type: '',
            title: '',
            body: ''},

        validate: {
            // type: (value) => (value.length < 1 ? 'Please choose a notification type' : null),
            title: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
            body: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
        },
    });
    
    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            // p='xl'
            zIndex={999}
        >
            <Center>
                <Text size='xl' weight={700} color={theme.colors.gray[6]} > Create Notification </Text>
            </Center>
            <form onSubmit={form.onSubmit((values) => onCreate(values))}>
                <Stack p='md'>
                    {/* <Select
                        label="Notification Type"
                        placeholder="Pick One"
                        itemComponent={SelectItem}
                        maxDropdownHeight={400}
                        data={[
                            { image: <IconExclamationCircle/>, label: 'Alert', value: 'alert',},
                            { image: <IconMail/>, label: 'Message', value: 'message', },
                        ]} 
                        {...form.getInputProps('type')}
                    /> */}
                    <TextInput 
                        label="Title" 
                        {...form.getInputProps('title')}/>
                    <Textarea
                        autosize
                        minRows={2}
                        label="Message Body" 
                        {...form.getInputProps('body')}/>
                </Stack>
            
                <Flex justify="flex-start"
                            align="center"
                            direction="row"
                            wrap="wrap" 
                            gap="md" p='md'>
                    <Button type="submit" sx={{flex: 1}} >
                        Submit
                    </Button>
                    <Button color='gray' sx={{flex: 1}} onClick={() => {onClose()}}>
                        Cancel
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}
