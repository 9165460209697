import { useEffect, useRef } from 'react';
import {
    useMantineTheme, Button, Flex, TextInput, Center, Text, Modal, Stack, PasswordInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';

export default function NewSportsModal({ opened, onClose, onSubmit, isSuccess }) {
    const theme = useMantineTheme();
    const resetRef = useRef(null);

    useEffect(() => {
        if (isSuccess || onClose) {
            form.reset()
        }
    }, [isSuccess]);

    const form = useForm({
        initialValues: { sports_name: '' },

        validate: {
            sports_name: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
        },
    });

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            // p='xl'
            style={{ zIndex: 999 }}
        >
            <Center>
                <Text size='xl' weight={700} color={theme.colors.gray[6]}> Create Sports </Text>
            </Center>
            <form onSubmit={form.onSubmit((values) => onSubmit({ ...values}))}>
                <Stack p='md'>
                    <TextInput
                        label="Title"
                        {...form.getInputProps('sports_name')}
                    />
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" p='md'>
                    <Button type="submit" sx={{ flex: 1 }}>
                        Submit
                    </Button>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose(); }}>
                        Cancel
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}