import { forwardRef, useState, useRef, useEffect  } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, Grid, Select, Loader, Skeleton, Stack, ActionIcon, MultiSelect 
} from '@mantine/core';
import { IconArrowLeft, IconCheck, IconExclamationCircle, IconCalendar, IconClock  } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import moment from 'moment';
import momenttz from 'moment-timezone';
import { DatePickerInput, TimeInput  } from '@mantine/dates';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import { formatDOW, formatDOW_NextDay, formatm_from_time } from '../../utils/DateTime';
import useAuth from '../../hooks/useAuth';

const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">+60 {label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

const MAX_DURATION = 6

export default function LAAddSchedule() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    // const { id } = useParams()
    const currentDate = moment().tz("Asia/Kuala_Lumpur").toDate()
    const ref = useRef();
    const { auth } = useAuth();
    const id = auth?.locationId

    const [openingHours, setOpeningHours] = useState([])
    const [sports, setSports] = useState([])
    const [courtsSelection, setCourtsSelection] = useState([])

    const [date, setDate] = useState(currentDate)

    const [durationSelection, setDurationSelection] = useState([])

    const form = useForm({
        initialValues: {
            sport: null,
            date: currentDate,
            time: '',
            duration: '',
            courts: [],
        },

        validate: {
            sport: (value) => (value === null ? 'Please fill in the empty field' : null),
            time: (value) => (value.trim().length === 0 ? 'Please fill in the empty field' : null),
            duration: (value) => (value.trim().length === 0 ? 'Please fill in the empty field' : null),
            courts: (value) => (value.length === 0 ? 'Please select court(s)' : null),
        },
    });


    useEffect(()=>{
        const generateDuration = () => {
            let minute = formatm_from_time(form.values.time)
            const selectedTimeFloat = parseFloat(form.values.time) + (parseInt(minute) > 30 ? 1 : parseInt(minute) > 0 ? 0.5 : 0)
            const opening = openingHours.find(x => x.day === formatDOW(date))
            const openingNextDay = openingHours.find(x => x.day === formatDOW_NextDay(date))
            let durationArr = []
            let hour = 1;
            if(opening?.close === 24 && openingNextDay?.open === 0){
                for (let i = 1; i < openingNextDay?.close; i++) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Minutes' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }else{
                for (let i = selectedTimeFloat; i < opening?.close-0.5; i=i+0.5) {
                    durationArr.push({
                        label: `${Math.floor(hour)} Hour${Math.floor(hour)>1 ? 's' : ''} ${(hour % 1).toFixed(1) === '0.5' ? '30 Minutes' : ''}`,
                        value: `${hour}`
                    })
                    hour = hour + 0.5;
                    if(hour>MAX_DURATION) break;
                }
            }
            setDurationSelection(durationArr)
        }
        if(form.values.time.trim().length > 0)
            generateDuration()
    },[openingHours, form.values.time])

    useEffect(()=>{
        form.setFieldValue('time', '')
        form.setFieldValue('duration', '')
        form.setFieldValue('courts', [])

        setCourtsSelection([])
    },[form.values.sport, date])
    useEffect(()=>{
        form.setFieldValue('duration', '')
        form.setFieldValue('courts', [])

        setCourtsSelection([])
    },[form.values.time])
    useEffect(()=>{
        form.setFieldValue('courts', [])

        if(form.values.duration){
            setCourtsSelection([])
            getAvailableCourts()
        }
    },[form.values.duration])

    const getLocationQuery = useQuery(
        "venue-schedule",
        async ({ signal }) => (await axiosPrivate.post("/locations/read-by-location-id", {locationId: id, uid: '' }, { signal })).data.location,
        {
            initialData: null,
            onSuccess: (res) => {
                setOpeningHours(JSON.parse(res?.opening_hours ? res?.opening_hours : '[]'))
                form.setFieldValue('sport', res?.sports[0] ? res?.sports[0].value : 0)
                setSports(res?.sports)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            }
        }
    );

    const getAvailableCourtsMutation = useMutation(
        (data) => axiosPrivate.post("/crm/schedule/get-available-courts", data, {headers: {'Content-Type': 'multipart/form-data'}}),
        {
            onSuccess: (res) => {
                // console.log(res?.data?.courts)
                let courts = res?.data?.courts
                if(courts.length>0){
                    let courtSelection = courts.map((c)=>({value: c.courtId, label: c.name, price: c.price}))
                    // console.log(courtSelection)
                    setCourtsSelection(courtSelection)
                }
            },
            onError:(err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        }
    );


    const addScheduleMutation = useMutation(
        (data) => axiosPrivate.post(`crm/schedule/add-schedule`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'add-schedule',
                color: 'teal',
                title: `Schedule added`,
                message: `The Schedule is successfully added.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("venue-schedule")
            // queryClient.invalidateQueries("getUserSelection")
            goBack()
        },
        onError: (err) => {
            let errMsg = err?.response?.data?.message
            notifications.update({
                id: 'add-schedule',
                color: 'red',
                title: 'Error',
                message: errMsg ? errMsg : 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 6000,
            });
        }
        }
    );

    const getAvailableCourts = () => {
        getAvailableCourtsMutation.mutate({ locationId: id, type: form.values.sport, date: date, time: form.values.time, duration: form.values.duration })
    }

    const addSchedule = (data) => {

        let total_price = 0
        for(let item of data.courts){
            let obj = courtsSelection.find(c => c.value === item);
            if(obj){
                total_price = total_price + obj.price
            }
        }

        let inputFields = {
            uid: auth?.admin_id,
            locationId: id,
            type: data.sport,
            date: date,
            time: data.time,
            duration: data.duration,
            courtArr: JSON.stringify(data.courts),
            price: total_price,
            total_price: total_price
        }

        notifications.show({
            id: 'add-schedule',
            loading: true,
            title: `Adding schedule`,
            message: `The schedule is being added.`,
            autoClose: false,
        })
        addScheduleMutation.mutate(inputFields)
    }


    const goBack = () => {
        navigate(`/`, { replace: true })
    }

    return (
        <>
            <Group position='left'>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={addScheduleMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Add Schedule </Text>
                {getLocationQuery.isFetching ?
                    <Loader size={'sm'} variant='dots'/>
                    : null
                }
            </Group>
            <Divider my="md" />
            <Skeleton visible={getLocationQuery.isFetching  || getLocationQuery.isError } animate={!getLocationQuery.isError}>
            <form onSubmit={form.onSubmit((values) => { addSchedule(values); })}>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Stack>
                            <Select
                                label="Select Sport"
                                placeholder="Select sport"
                                data={sports}
                                // value={selectedSport}
                                // onChange={setSelectedSport}
                                disabled={addScheduleMutation.isLoading}
                                {...form.getInputProps('sport')}
                            />
                            <DatePickerInput
                                label="Select Date"
                                valueFormat="DD/MM/YYYY"
                                rightSection={<IconCalendar size="1.1rem" stroke={1.5} color='#BABABA'/>}
                                placeholder="Select date"
                                value={date}
                                onChange={setDate}
                                minDate={currentDate}
                                disabled={addScheduleMutation.isLoading}
                                // {...form.getInputProps('date')}
                            />
                            <TimeInput
                                label="Select Start Time"
                                ref={ref}
                                // value={selectedTime}
                                // onChange={(event) => setSelectedTime(event.currentTarget.value)}
                                {...form.getInputProps('time')}
                                rightSection={
                                    <ActionIcon onClick={() => ref.current.showPicker()}>
                                    <IconClock size="1rem" stroke={1.5} />
                                    </ActionIcon>
                                }
                                disabled={addScheduleMutation.isLoading}
                            />
                            <Select
                                label="Select Duration"
                                placeholder="Select duration"
                                data={durationSelection}
                                // value={selectedSport}
                                // onChange={setSelectedSport}
                                {...form.getInputProps('duration')}
                                disabled={addScheduleMutation.isLoading}
                            />
                            <MultiSelect 
                                label="Select Court(s)"
                                placeholder="Search court"
                                searchable
                                nothingFound="No Courts"
                                // itemComponent={SelectItem}
                                data={courtsSelection}
                                {...form.getInputProps('courts')}
                                disabled={addScheduleMutation.isLoading}
                                // filter={(value, item) =>
                                //     item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                //     item.description.toLowerCase().includes(value.toLowerCase().trim())
                                // }
                        />
                        </Stack>
                    </Grid.Col>
                </Grid>

                <Grid my='xl'>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button color="gray" fullWidth onClick={goBack} disabled={addScheduleMutation.isLoading}>
                            Back
                        </Button>
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button type="submit" fullWidth loading={addScheduleMutation.isLoading}>
                            Submit
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
            </Skeleton>
        </>
    );
}
