import {
    useMantineTheme, Button, Flex, Center, Text, Modal, LoadingOverlay
} from '@mantine/core';

export default function RevertConfirmationModal({ opened, onClose, onClick, isLoading = false }) {
    const theme = useMantineTheme();

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            // p='xl'
            zIndex={999}
        >
            <LoadingOverlay visible={isLoading} overlayBlur={2} radius='lg' />
            <Center>
                <Text size='lg' ta="center" weight={600} color={theme.colors.gray[6]} p='md'> Do you wish to restore back the data? </Text>
            </Center>
            <Flex justify="flex-start"
                align="center"
                direction="row"
                wrap="wrap"
                gap="md" p='md'>
                <Button sx={{ flex: 1 }} onClick={onClick}>
                    Confirm
                </Button>
                <Button color='gray' sx={{ flex: 1 }} onClick={onClose}>
                    Cancel
                </Button>
            </Flex>
        </Modal>
    )
}