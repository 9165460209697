import { forwardRef } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, Grid, Select, Loader, Skeleton
} from '@mantine/core';
import { IconArrowLeft, IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">+60 {label}</Text>
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const SelectItem2 = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              Discount RM{description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

export default function AddMember() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id } = useParams()

    const form = useForm({
        initialValues: {
            uid: null,
            type: null,
        },

        validate: {
            uid: (value) => (value === null? 'Please fill in the empty field' : null),
            type: (value) => (value === null ? 'Please fill in the empty field' : null),
        },
    });


    const getUserSelectionQuery = useQuery(
        "getUserSelection",
        async ({ signal }) => (await axiosPrivate.post("/crm/membership/get-non-member-user-selection", { locationId: id }, { signal })).data.accounts,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log('User Selection')
                // console.log(res)
                // let resOpeningHours = JSON.parse(res.opening_hours ? res.opening_hours : '[]')
                // setOpeningHours(resOpeningHours);
                // setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getTypeSelectionQuery = useQuery(
        "getTypeSelection",
        async ({ signal }) => (await axiosPrivate.post("/crm/membership/get-membership-type-selection", { locationId: id }, { signal })).data.membershipType,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log('Membership Type')
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getLocationQuery = useQuery(
        "getLocation",
        async ({ signal }) => (await axiosPrivate.post("/crm/locations/read-by-location-id", { locationId: id }, { signal })).data.location,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res.opening_hours)
                // let resOpeningHours = JSON.parse(res.opening_hours ? res.opening_hours : '[]')
                // setOpeningHours(resOpeningHours);
                // setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const addMemberMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership/add`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'add-member',
                color: 'teal',
                title: `Member added`,
                message: `The member is successfully added.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("membership")
            queryClient.invalidateQueries("getUserSelection")
            goBack()
        },
        onError: () => {
            notifications.update({
                id: 'add-member',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const addMember = (data) => {
        const formData = new FormData();

        formData.append('uid', data.uid);
        formData.append('locationId', id);
        formData.append('type', data.type);

        notifications.show({
            id: 'add-member',
            loading: true,
            title: `Adding member`,
            message: `The member is being added.`,
            autoClose: false,
        })
        addMemberMutation.mutate(formData)
    }


    const goBack = () => {
        navigate(`/venue/membership/${id}`, { replace: true })
    }

    return (
        <>
            <Group position='left'>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={addMemberMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Create Member </Text>
                {getLocationQuery.isFetching ?
                    <Loader size={'sm'} variant='dots'/>
                    : null
                }
            </Group>
            <Divider my="md" />
            <Skeleton visible={getLocationQuery.isFetching || getUserSelectionQuery.isFetching || getLocationQuery.isError || getUserSelectionQuery.isError} animate={!getLocationQuery.isError || !getUserSelectionQuery.isError}>
            <form onSubmit={form.onSubmit((values) => { addMember(values); })}>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Select
                            label="Select User"
                            placeholder="Search user"
                            searchable
                            nothingFound="User not found"
                            itemComponent={SelectItem}
                            data={getUserSelectionQuery.data}
                            {...form.getInputProps('uid')}
                            disabled={addMemberMutation.isLoading}
                            filter={(value, item) =>
                                item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
                                item.description.toLowerCase().includes(value.toLowerCase().trim())
                            }
                            icon={<Text size={14}>+60</Text>}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Select
                            label="Select Membership Type"
                            placeholder="Search membership type"
                            searchable
                            nothingFound="Membership type not found"
                            itemComponent={SelectItem2}
                            data={getTypeSelectionQuery.data}
                            {...form.getInputProps('type')}
                            disabled={addMemberMutation.isLoading}
                            filter={(value, item) =>
                                item.label.toLowerCase().includes(value.toLowerCase().trim())
                            }
                        />
                    </Grid.Col>
                </Grid>

                <Grid my='xl'>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button color="gray" fullWidth onClick={goBack} disabled={addMemberMutation.isLoading}>
                            Back
                        </Button>
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button type="submit" fullWidth loading={addMemberMutation.isLoading}>
                            Submit
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
            </Skeleton>
        </>
    );
}
