import { useState, useEffect } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, TextInput,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import {
  //booleanFilterFn,
  DataGrid,
  //dateFilterFn,
  //highlightFilterValue,
  //numberFilterFn,
  //stringFilterFn,
} from 'mantine-data-grid';
import { IconTrash, IconSend, IconPencil, IconCirclePlus, IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import EditNotificationModal from '../../components/notification/EditNotificationModal';
import CreateNotificationModal from '../../components/notification/CreateNotificationModal';
import SendNotificationModal from '../../components/notification/SendNotificationModal';
import RevertConfirmationModal from '../../components/general/RevertConfirmationModal';

export default function ManageNotification() {
    const theme = useMantineTheme()
    const queryClient = useQueryClient();
    const axiosPrivate = useAxiosPrivate();

    const [openCModal, setOpenCModal] = useState(false);
    const [openCNModal, setOpenCNModal] = useState(false);
    const [openENModal, setOpenENModal] = useState(false);
    const [openRCModal, setOpenRCModal] = useState(false);
    const [openCSNModal, setOpenCSNModal] = useState(false);

    const [deleteId, setDeleteId] = useState('');
    const [nid, setNid] = useState('');
    const [notificationID, setNotificationID] = useState('');
    const [notificationStatus, setNotificationStatus] = useState("delet");

    const { isLoading, isFetching, isError, error, data } = useQuery(
        "notification",
        async ({signal}) => (await axiosPrivate.get(`/crm/notifications/read`, {}, {signal})).data.notifications,
        {
            initialData: [],
            onError: () => {
                notifications.show({
                    id: 'error',
                    title: `Error`,
                    message: `Unable to load data`,
                })
            }
        }
    );

  const createNotificationMutation = useMutation(
    (data) => axiosPrivate.post(`/crm/notifications/add`, data),
    {
        onSuccess: () => {
            notifications.update({
                id: 'create-notification',
                color: 'teal',
                title: 'Notification created',
                message: 'The notification is successfully created',
                icon: <IconCheck size={16} />,
                autoClose: 2000,
            });
            queryClient.invalidateQueries("notification")
        },
        onError: () => {
            notifications.update({
                id: 'create-notification',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
    },
  );
 
  const updateNotificationMutation = useMutation(
    (data) => axiosPrivate.post(`/crm/notifications/update`, data),
    {
      onSuccess: () => {
        notifications.update({
            id: 'update-notification',
            color: 'teal',
            title: 'Notification updated',
            message: 'The notification is successfully updated',
            icon: <IconCheck size={16} />,
            autoClose: 2000,
          });
        queryClient.invalidateQueries("notification")
      },
      onError: () => {
        notifications.update({
            id: 'update-notification',
            color: 'red',
            title: 'Error',
            message: 'An error has occurred.',
            icon: <IconExclamationCircle size={16} />,
            autoClose: 4000,
          });
        }
    }
  );

  const deleteNotificationMutation = useMutation(
    (notification_id) => axiosPrivate.post(`crm/notifications/delete`, {notification_id}),
    {
      onSuccess: () => {
        notifications.update({
            id: 'delete-notification',
            color: 'teal',
            title: `Notification ${notificationStatus}ed`,
            message: `The notification is successfully ${notificationStatus}ed.`,
            icon: <IconCheck size={16} />,
            autoClose: 2000,
          });
        queryClient.invalidateQueries("notification")
      },
      onError: () => {
        notifications.update({
            id: 'delete-notification',
            color: 'red',
            title: 'Error',
            message: 'An error has occurred.',
            icon: <IconExclamationCircle size={16} />,
            autoClose: 4000,
          });
        }
    }
  );

  const sendNotificationMutation = useMutation(
    (notification_id) => axiosPrivate.post(`crm/notifications/send`, {notification_id}),
    {
        onSuccess: () => {
            notifications.update({
                id: 'sent-notification',
                color: 'teal',
                title: 'Notification sent',
                message: 'The notification has been sent successfully to all devices.',
                icon: <IconCheck size={16} />,
                autoClose: 2000,
              });
        },
        onError: () => {
            notifications.update({
                id: 'sent-notification',
                color: 'red',
                title: 'Error',
                message: 'A problem has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
              });
        }
    }
  );

  const sendNotification = () => {
    setOpenCSNModal(false)
    notifications.show({
        id: 'sent-notification',
        loading: true,
        title: 'Notification sending',
        message: 'The notification is being sent to all devices.',
        autoClose: false,
    })
    sendNotificationMutation.mutate(nid)
  }

  const deleteNotification = () => {
    // console.log('delete: ' + deleteId);
    setOpenCModal(false)
    setOpenRCModal(false)
    notifications.show({
        id: 'delete-notification',
        loading: true,
        title: `Notification ${notificationStatus}ing`,
        message: `The notification is being ${notificationStatus}ed.`,
        autoClose: false,
    })
    deleteNotificationMutation.mutate(deleteId)
  }

    const onCreate = async (data) => {
        setOpenCNModal(false)
        let submitNotification = {
            title: data.title,
            body: data.body,
            type: 'message',
        }  
        notifications.show({
            id: 'create-notification',
            loading: true,
            title: 'Notification creating',
            message: 'The notification is being created.',
            autoClose: false,
        })
        createNotificationMutation.mutate(submitNotification);
    }

    const onEdit = async (data) => {
        setOpenENModal(false)
        let submitUpdatedNotification = {
            notification_id: data.notification_id,
            title: data.title,
            body: data.body,
            type: 'message',
        }
        notifications.show({
            id: 'update-notification',
            loading: true,
            title: 'Notification updating',
            message: 'The notification is being updated.',
            autoClose: false,
        })
        updateNotificationMutation.mutate(submitUpdatedNotification);
    }

  return (
    <>
      <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to delete it?" onClick={deleteNotification} />
      <CreateNotificationModal opened={openCNModal} onClose={() => setOpenCNModal(false)} onCreate={onCreate} />
      <EditNotificationModal data={notificationID} opened={openENModal} onClose={() => setOpenENModal(false)} onEdit={onEdit} />
      <SendNotificationModal opened={openCSNModal} onClose={() => setOpenCSNModal(false)} text2="Are you sure you want to send this notification?" onClick={sendNotification}/>
      <RevertConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} onClick={deleteNotification} />

      <Group position='apart'>
        <Text size={'xl'} weight={600}>Manage Notification</Text>
        <Button onClick={() => setOpenCNModal(true)} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
            Create Notification
        </Button>
      </Group>
      <Divider my="md" />
    <DataGrid
      data={data}
      loading={isFetching}
      striped
      highlightOnHover
      withGlobalFilter
      withPagination
      //withColumnFilters
      withSorting
      //withColumnResizing
      columns={[
        {
            header: 'No.',
            cell: (cell) => {
                return <Text>{cell.row.index + 1}</Text>;
            },
            size: 60,
        },
        {
            accessorKey: 'title',
            header: 'Title',
            size: 150,
        },
        {
            accessorKey: 'body',
            header: 'Content',
            size: 450,
        },
        {
            header: 'Action',
            cell: (cell) => {
                return <Group position="center">
                    <>
                        <Button onClick={() => { setNid(cell.row.original.notification_id); setOpenCSNModal(true) }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconSend size={18} />} size="xs" color="green"> Send </Button>
                        <Button onClick={() => { setNotificationID(cell.row.original); setOpenENModal(true) }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow"> Edit </Button>
                        <Button onClick={() => { setDeleteId(cell.row.original.notification_id); setOpenCModal(true); setNotificationStatus("delet") }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconTrash size={18} />} size="xs" color="red"> Delete </Button>
                    </>
                </Group>;
            },
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            size: 280,
        },
        ]}
      />
    </>

  );
}