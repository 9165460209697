
import { useState } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme,
} from '@mantine/core';
import {
    DataGrid,
} from 'mantine-data-grid';
import { IconPower, IconExclamationCircle, IconCheck } from '@tabler/icons-react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { notifications } from '@mantine/notifications';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ManageDevice() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();

    const [openCModal, setOpenCModal] = useState(false);
    const [openRCModal, setOpenRCModal] = useState(false);

    const [actionId, setActionId] = useState('');

    const { isFetching, data, } = useQuery(
        "devices",
        async ({ signal }) => (await axiosPrivate.get(`crm/device/read`, {}, { signal })).data.devices,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                    color: 'red',
                })
            }
        },

    );


    const switchOnMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/control-device-by-court-id`, {courtId}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'on-device',
                    color: 'teal',
                    title: `Device on`,
                    message: `The device is successfully switched on.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("devices")
            },
            onError: () => {
                notifications.update({
                    id: 'on-device',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const switchOffMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/control-device-by-court-id`, {courtId}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'off-device',
                    color: 'teal',
                    title: `Device off`,
                    message: `The device is successfully switched off.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("devices")
            },
            onError: () => {
                notifications.update({
                    id: 'off-device',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const switchOnDevice = () => {
        setOpenCModal(false);
        setOpenRCModal(false);
        notifications.show({
            id: 'on-device',
            loading: true,
            title: `Turning on device`,
            message: `The device is being switched on.`,
            autoClose: false,
        })
        switchOnMutation.mutate(actionId)
    }

    const switchOffDevice = () => {
        setOpenCModal(false);
        setOpenRCModal(false);
        notifications.show({
            id: 'off-device',
            loading: true,
            title: `Turning off device`,
            message: `The device is being switched off.`,
            autoClose: false,
        })
        switchOffMutation.mutate(actionId)
    }

    return (
        <>
            {/* Confirmation Modal */}
            <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to on device?" onClick={switchOnDevice} />
            <ConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} text="Are you sure you want to off device?" onClick={switchOffDevice} />

            <Group position='apart'>
                <Text size={'xl'} weight={600}> Manage Devices </Text>
            </Group>
            <Divider my="md" />
            <DataGrid
                data={data}
                loading={isFetching}
                striped
                highlightOnHover
                withGlobalFilter
                withPagination
                //withColumnFilters
                withSorting
                //withColumnResizing
                columns={[
                    {
                        header: 'No.',
                        cell: (cell) => {
                            return <Text>{cell.row.index + 1}</Text>;
                        },
                        size: 60,
                    },
                    {
                        accessorKey: 'device_id',
                        header: 'Device ID',
                        size: 150,
                    },
                    {
                        accessorKey: 'locationName',
                        header: 'Location',
                        size: 150,
                    },
                    {
                        accessorKey: 'name',
                        header: 'Court',
                        size: 150,
                    },
                    {
                        accessorKey: 'sport',
                        header: 'Sport',
                        size: 150,
                    },
                    {
                        header: 'Action',
                        cell: (cell) => {
                            return <Group position="center">
                                {cell.row.original.switch ?
                                    <>
                                        <Button onClick={() => { setActionId(cell.row.original.courtId); setOpenRCModal(true);}} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPower size={18} />} size="xs" color="orange">Turn Off</Button>
                                    </>
                                    :
                                    <>
                                        <Button onClick={() => { setActionId(cell.row.original.courtId); setOpenCModal(true);}} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPower size={18} />} size="xs" color="blue">Turn On</Button>
                                    </>
                                }
                            </Group>;
                        },
                        enableColumnFilter: false,
                        enableSorting: false,
                        enableGlobalFilter: false,
                        size: 265,
                    },
                ]}
            />
        </>
    );
}