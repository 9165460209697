import { AppShell, MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { useLocalStorage } from '@mantine/hooks';
import { useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'

import useAuth from './hooks/useAuth';
import RequireAuth from './components/general/RequireAuth';
import PersistLogin from './components/general/PersistLogin';


import SideNavbar from './components/general/SideNavbar';
import TopHeader from './components/general/TopHeader';

import Login from './pages/auth/Login';
import NotFound from './pages/misc/NotFound';

import ManageVenue from './pages/venue/ManageVenue';
import AddVenue from './pages/venue/AddVenue';
import EditVenue from './pages/venue/EditVenue';

import ManageCourt from './pages/court/ManageCourt';
import AddCourt from './pages/court/AddCourt';
import EditCourt from './pages/court/EditCourt';

import ManageMembership from './pages/membership/ManageMembership';
import AddMember from './pages/membership/AddMember';
import EditMember from './pages/membership/EditMember';

import ViewSchedule from './pages/schedule/ViewSchedule';
import AddSchedule from './pages/schedule/AddSchedule';

import ManageAdmin from './pages/admin/ManageAdmin';
import ManageNotification from './pages/notification/ManageNotification';
import ManageSport from './pages/sport/ManageSport';
import ManageDevice from './pages/device/ManageDevice';

import ManageLocationAdmin from './pages/admin/ManageLocationAdmin';

import Dashboard from './pages/dashboard/Dashboard';

//  LOCATION_ADMIN
import LAViewSchedule from './pages/schedule/LAViewSchedule';
import LAViewVenue from './pages/venue/LAViewVenue';
import LAAddSchedule from './pages/schedule/LAAddSchedule';

import LAManageMembership from './pages/membership/LAManageMembership';
import LAAddMember from './pages/membership/LAAddMember';
import LAEditMember from './pages/membership/LAEditMember';

import LAManageCourt from './pages/court/LAManageCourt';
import LAAddCourt from './pages/court/LAAddCourt';
import LAEditCourt from './pages/court/LAEditCourt';

import LAManageStaff from './pages/staff/LAManageStaff';


import { SUPER_ADMIN, LOCATION_ADMIN, LOCATION_STAFF } from './utils/Constants';

function App() {

  const [colorScheme, setColorScheme] = useLocalStorage({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  });


  const toggleColorScheme = (value) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  const location = useLocation();

  const { auth } = useAuth();

  const [opened, setOpened] = useState(false);


  var showHeaderNavbar = true;
  if (location.pathname === '/login' || location.pathname === '/404' || !auth?.admin_id) {
    showHeaderNavbar = false
  }
  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{
        colorScheme: colorScheme,
        colors: {
          brand: ['#FFF5F5', '#FFE3E3', '#FFC9C9', '#FFA8A8', '#FF8787', '#FF6B6B', '#FA5252', '#F03E3E', '#E03131', '#C92A2A'],
          iCourtOrange: ["#fff1e1", "#ffe1cd", "#fdc29e", "#f9a16a", "#f6853e", "#f57322", "#f56912", "#da5805", "#c34d00", "#ab4000"],
        },
        primaryColor: 'iCourtOrange',
        activeStyles: { transform: 'scale(0.98)' }
      }} withGlobalStyles withNormalizeCSS>
        <Notifications position="top-center" zIndex={2077} />
        <AppShell
          styles={{
            main: { transition: "padding-left 500ms ease" }
          }}
          padding="md"
          navbarOffsetBreakpoint="sm"
          navbar={showHeaderNavbar ? <SideNavbar setOpened={setOpened} opened={opened} /> : null}
          header={showHeaderNavbar ? <TopHeader setOpened={setOpened} opened={opened} /> : null}>
          <Routes>
            
            {/* Protected Route */}
            <Route element={<PersistLogin />}>
                
                <Route element={<RequireAuth allowedRoles={[SUPER_ADMIN, LOCATION_ADMIN, LOCATION_STAFF]} />}>
                    <Route path='/' element={<Dashboard />} />
                </Route>
                
                <Route element={<RequireAuth allowedRoles={[SUPER_ADMIN]} />}>
                    <Route path='/venue/add' element={<AddVenue />} />
                    <Route path='/venue/edit/:id' element={<EditVenue />} />
                    <Route path='/venue/court/:id' element={<ManageCourt />} />
                    <Route path='/venue/court/add/:id' element={<AddCourt />} />
                    <Route path='/venue/court/edit/:id/:courtId' element={<EditCourt />} />
                    <Route path='/venue/schedule/:id' element={<ViewSchedule />} />
                    <Route path='/venue/schedule/add/:id' element={<AddSchedule />} />
                    <Route path='/venue/membership/:id' element={<ManageMembership />} />
                    <Route path='/venue/membership/add/:id' element={<AddMember />} />
                    <Route path='/venue/membership/edit/:id/:membershipId' element={<EditMember />} />
                    <Route path='/venue/admin/:id' element={<ManageLocationAdmin />} />

                    <Route path='/notification' element={<ManageNotification />} />
                    <Route path='/sport' element={<ManageSport />} />
                    <Route path='/device' element={<ManageDevice />} />
                    <Route path='/admin' element={<ManageAdmin />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[LOCATION_ADMIN, LOCATION_STAFF]} />}>
                    {/* <Route path='/schedule' element={<LAViewSchedule />}/> */}
                    <Route path='/schedule/add' element={<LAAddSchedule />}/>
                </Route>

                <Route element={<RequireAuth allowedRoles={[LOCATION_ADMIN]} />}>
                    <Route path='/membership' element={<LAManageMembership />}/>
                    <Route path='/membership/add' element={<LAAddMember />}/>
                    <Route path='/membership/edit/:membershipId' element={<LAEditMember />}/>

                    <Route path='/court' element={<LAManageCourt />}/>
                    <Route path='/court/add' element={<LAAddCourt />}/>
                    <Route path='/court/edit/:courtId' element={<LAEditCourt />} />
                    <Route path='/venue' element={<LAViewVenue />}/>

                    <Route path='/staff' element={<LAManageStaff />}/>
                </Route>
            </Route>

            {/* Public Route */}
            <Route path='/login' element={<Login />} />

            <Route path='/404' element={<NotFound />} />

            {/* If route not found redirect to home page for now */}
            <Route path="*" element={<Navigate to="/404" replace />} />
          </Routes>
        </AppShell>
      </MantineProvider>
    </ColorSchemeProvider>
  );
}

export default App;
