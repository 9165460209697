import { useState, } from 'react';
import {
  Text, Divider, Button, Group, useMantineTheme,
} from '@mantine/core';
import {
  DataGrid,
} from 'mantine-data-grid';
import { IconCirclePlus, IconPencil, IconArrowLeft, IconCheck, IconExclamationCircle, IconArrowBack, IconEyeOff, IconPower, IconUsers } from '@tabler/icons-react';
import { notifications, } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ManageCourt() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id } = useParams()

    const [actionId, setActionId] = useState('');

    const [openCModal, setOpenCModal] = useState(false); //delete
    const [openRCModal, setOpenRCModal] = useState(false); //restore

    const [openOnCModal, setOpenOnCModal] = useState(false); //on
    const [openOffCModal, setOpenOffCModal] = useState(false); //off


    const { isFetching, data: courts } = useQuery(
        "courts",
        async ({ signal }) => (await axiosPrivate.post(`crm/courts/read-by-location-id`, {locationId: id}, { signal })).data.courts,
        {
        initialData: [],
        onSuccess: (res) => {
            // console.log(res)
        },
        onError: (err) => {
            let errMsg = err?.response?.data?.message
            notifications.show({
                title: 'Error',
                message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                color: 'red',
            })
        }
        }
    );


    const disableCourtMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/enable-disable`, { courtId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'disable-court',
                color: 'teal',
                title: `Court disabled`,
                message: `The court is successfully disabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("courts")
        },
        onError: () => {
            notifications.update({
                id: 'disable-court',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const restoreCourtMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/enable-disable`, { courtId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'restore-court',
                color: 'teal',
                title: `Court enabled`,
                message: `The court is successfully enabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("courts")
        },
        onError: () => {
            notifications.update({
                id: 'restore-court',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const switchOnMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/control-device-by-court-id`, {courtId}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'on-device',
                    color: 'teal',
                    title: `Device on`,
                    message: `The device is successfully switched on.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("devices")
                queryClient.invalidateQueries("courts")
            },
            onError: () => {
                notifications.update({
                    id: 'on-device',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const switchOffMutation = useMutation(
        (courtId) => axiosPrivate.post(`crm/courts/control-device-by-court-id`, {courtId}),
        {
            onSuccess: () => {
                notifications.update({
                    id: 'off-device',
                    color: 'teal',
                    title: `Device off`,
                    message: `The device is successfully switched off.`,
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("devices")
                queryClient.invalidateQueries("courts")
            },
            onError: () => {
                notifications.update({
                    id: 'off-device',
                    color: 'red',
                    title: 'Error',
                    message: 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const switchOnDevice = () => {
        setOpenOnCModal(false);
        setOpenOffCModal(false);
        notifications.show({
            id: 'on-device',
            loading: true,
            title: `Turning on device`,
            message: `The device is being switched on.`,
            autoClose: false,
        })
        switchOnMutation.mutate(actionId)
    }

    const switchOffDevice = () => {
        setOpenOnCModal(false);
        setOpenOffCModal(false);
        notifications.show({
            id: 'off-device',
            loading: true,
            title: `Turning off device`,
            message: `The device is being switched off.`,
            autoClose: false,
        })
        switchOffMutation.mutate(actionId)
    }

    const disableCourt = () => {
        setOpenCModal(false);
        notifications.show({
            id: 'disable-court',
            loading: true,
            title: `Court disabling`,
            message: `The court is being disable.`,
            autoClose: false,
        })
        disableCourtMutation.mutate(actionId)
    }

    const restoreCourt = () => {
        setOpenRCModal(false);
        notifications.show({
            id: 'restore-court',
            loading: true,
            title: `Court restoring`,
            message: `The court is being restored.`,
            autoClose: false,
        })
        restoreCourtMutation.mutate(actionId)
    }


  const onClickCreateCourt = () => {
    navigate(`/venue/court/add/${id}`);
  }

//   const onClickCreateMultipleCourt = () => {
//     navigate(`/venue/court/add-multiple/${id}`);
//   }

  const goBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <>
      <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to disable it?" onClick={disableCourt} />
      <ConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} text="Are you sure you want to enable it?" onClick={restoreCourt} />
      {/* Confirmation Modal */}
      <ConfirmationModal opened={openOnCModal} onClose={() => setOpenOnCModal(false)} text="Are you sure you want to on device?" onClick={switchOnDevice} />
        <ConfirmationModal opened={openOffCModal} onClose={() => setOpenOffCModal(false)} text="Are you sure you want to off device?" onClick={switchOffDevice} />

      <Group position='apart'>
            <Group>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={disableCourtMutation.isLoading || restoreCourtMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Manage Courts </Text>
            </Group>
            <Group>
                {/* <Button onClick={onClickCreateMultipleCourt} leftIcon={<IconLayoutGridAdd size={18} />} color="green" size='xs'>
                    Add Multiple Courts
                </Button> */}
                <Button onClick={onClickCreateCourt} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                    Add Court
                </Button>
            </Group>
      </Group>
      <Divider my="md" />
      <DataGrid
        data={courts}
        loading={isFetching}
        striped
        highlightOnHover
        withGlobalFilter
        withPagination
        //withColumnFilters
        withSorting
        //withColumnResizing
        columns={[
          {
            header: 'No.',
            cell: (cell) => {
              return <Text>{cell.row.index + 1}</Text>;
            },
            size: 60,
          },
          {
            accessorKey: 'name',
            header: 'Name',
            size: 135,
          },
          {
            accessorKey: 'sport',
            header: 'Sport',
            size: 135,
          },
          {
            accessorKey: 'device_id',
            header: 'Device ID',
            size: 135,
          },
          {
            header: 'Action',
            cell: (cell) => {
              return <Group position="center">
                {cell.row.original.deleted ?
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/venue/court/edit/${id}/${cell.row.original.courtId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.courtId); setOpenRCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Enable</Button>
                  </>
                  :
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/venue/court/edit/${id}/${cell.row.original.courtId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.courtId); setOpenCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconEyeOff size={18} />} size="xs" color="red">Disable</Button>
                  </>
                }
                {cell.row.original.switch ?
                  <>
                    <Button disabled={!cell.row.original.device_id} onClick={() => { setActionId(cell.row.original.courtId); setOpenOffCModal(true);}} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPower size={18} />} size="xs" color="orange">Turn Off</Button>
                  </>
                  :
                  <>
                    <Button disabled={!cell.row.original.device_id} onClick={() => { setActionId(cell.row.original.courtId); setOpenOnCModal(true);}} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPower size={18} />} size="xs" color="blue">Turn On</Button>
                  </>
                }
              </Group>;
            },
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            size: 280,
          },
        ]}
      />
    </>
  );
}
