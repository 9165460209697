import { createStyles, Navbar, ScrollArea, Text } from '@mantine/core';
import {
  IconUsers,
  IconMail,
  IconCheckupList,
  IconList,
  IconCubePlus,
  IconCalendarMonth,
  IconAdjustments,
  IconSoccerField,
  IconUsersGroup,
} from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom'
import { useTimeout } from '@mantine/hooks'


import { SUPER_ADMIN, LOCATION_ADMIN } from '../../utils/Constants';
import useAuth from '../../hooks/useAuth';

const useStyles = createStyles((theme) => {

  return {
    navbar: {
        backgroundColor: theme.colorScheme === 'dark' && theme.colors.dark[8],
    },
    header: {
        paddingBottom: theme.spacing.md,
        marginBottom: theme.spacing.md * 1.5,
        borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    footer: {
        paddingTop: theme.spacing.md,
        marginTop: theme.spacing.md,
        borderTop: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
        }`,
    },

    link: {
        ...theme.fn.focusStyles(),
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: theme.fontSizes.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        padding: `${8}px ${10}px`,
        fontWeight: 500,
        cursor: 'pointer',
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
        

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
            color: theme.colorScheme === 'dark' ? theme.white : theme.black,
            borderColor: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
    },

    linkIcon: {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7],
        marginRight: theme.spacing.sm,
    },

    linkActive: {
        '&, &:hover': {
            backgroundColor: theme.fn.variant({ variant: theme.colorScheme === 'dark' ? 'light' : 'light', color: theme.primaryColor })
            .background,
            color: theme.fn.variant({ variant: theme.colorScheme === 'dark' ? 'light' : 'light', color: theme.primaryColor }).color,
            borderColor: theme.colors.iCourtOrange[6],
        },
    },

    linkDivider: {
        marginTop: theme.spacing.md,
        borderStyle: 'solid',
        borderWidth: 0,
        borderLeftWidth: 1,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[4],
        paddingLeft: theme.spacing.sm,
        paddingTop: 2,
        paddingBottom: 2,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }
  };
});


const super_admin_nav = [
  { link: '/', label: 'Manage Venue', icon: IconCheckupList, divider: true, dividerLabel: 'Venue' },
  { link: '/notification', label: 'Manage Notification', icon: IconMail, divider: true, dividerLabel: 'CMS' },
  { link: '/sport', label: 'Manage Sports', icon: IconList, divider: true,  dividerLabel: 'Sports' },
  { link: '/admin', label: 'Manage Super Admin', icon: IconUsers, divider: true, dividerLabel: 'Admin' },
  { link: '/device', label: 'Manage Device', icon: IconCubePlus, divider: true, dividerLabel: 'Device' },
//   { link: '/example', label: 'Example', icon: IconAddressBook, divider: true, dividerLabel: 'Example' },
//   { link: '/example', label: 'Example', icon: IconAddressBook, divider: false },
];

const location_admin_nav = [
    { link: '/', label: 'View Schedule', icon: IconCalendarMonth, divider: true, dividerLabel: 'Schedule' },
    { link: '/membership', label: 'Manage Membership', icon: IconUsersGroup, divider: true,  dividerLabel: 'Membership' },
    { link: '/court', label: 'Manage Court', icon: IconSoccerField, divider: true,  dividerLabel: 'Court' },
    { link: '/venue', label: 'Venue Information', icon: IconAdjustments, divider: true, dividerLabel: 'Venue' },
    { link: '/staff', label: 'Manage Staff', icon: IconUsers, divider: true,  dividerLabel: 'Staff' },
];

const location_staff_nav = [
    { link: '/', label: 'View Schedule', icon: IconAdjustments, divider: true, dividerLabel: 'Schedule' },
];

export default function SideNavbar({opened, setOpened}) {
  const location = useLocation();
  const { classes, cx, theme } = useStyles();
  const { start, clear } = useTimeout(() => setOpened(false), 100);
  const { auth } = useAuth();

    var links = []
    if(auth?.role === SUPER_ADMIN){
        links = super_admin_nav.map((item, index) => (
            <div key={`${item.label}-${index}`}>
                {
                item.divider ? 
                    <div className={classes.linkDivider}>
                        <Text size={12} color={theme.colorScheme === 'dark' ? 'gray.4' : 'gray.8'}>{item?.dividerLabel}</Text>
                    </div>
                :   null
                }
                <Link
                    className={cx(classes.link, { [classes.linkActive]: item.link === `/${location.pathname.split("/")[1]}` })}
                    onClick={() => {clear(); start();}}
                    to={item.link}
                >
                    <item.icon  size={20}/>
                    <span style={{whiteSpace: 'nowrap', marginLeft: 10}}>{item.label}</span>
                </Link>
            </div>
        ));
    }
    else if(auth?.role === LOCATION_ADMIN){
        links = location_admin_nav.map((item, index) => (
            <div key={`${item.label}-${index}`}>
                {
                item.divider ? 
                    <div className={classes.linkDivider}>
                        <Text size={12} color={theme.colorScheme === 'dark' ? 'gray.4' : 'gray.8'}>{item?.dividerLabel}</Text>
                    </div>
                :   null
                }
                <Link
                    className={cx(classes.link, { [classes.linkActive]: item.link === `/${location.pathname.split("/")[1]}` })}
                    onClick={() => {clear(); start();}}
                    to={item.link}
                >
                    <item.icon  size={20}/>
                    <span style={{whiteSpace: 'nowrap', marginLeft: 10}}>{item.label}</span>
                </Link>
            </div>
        ));
    }
    else{
        links = location_staff_nav.map((item, index) => (
            <div key={`${item.label}-${index}`}>
                {
                item.divider ? 
                    <div className={classes.linkDivider}>
                        <Text size={12} color={theme.colorScheme === 'dark' ? 'gray.4' : 'gray.8'}>{item?.dividerLabel}</Text>
                    </div>
                :   null
                }
                <Link
                    className={cx(classes.link, { [classes.linkActive]: item.link === `/${location.pathname.split("/")[1]}` })}
                    onClick={() => {clear(); start();}}
                    to={item.link}
                >
                    <item.icon  size={20}/>
                    <span style={{whiteSpace: 'nowrap', marginLeft: 10}}>{item.label}</span>
                </Link>
            </div>
        ));
    }

  return (
      <Navbar className={classes.navbar} hiddenBreakpoint="sm" width={{ base: !opened ? 1 : 0, sm: 220, lg: 300, }}
        sx={{
          overflow: "hidden",
          transition: "width 500ms ease, min-width 500ms ease",
        }} >
        <ScrollArea >
            <Navbar.Section pr="sm" pl="sm" pb="md" grow>
                {links}
            </Navbar.Section>
            {/* <Navbar.Section p="sm" className={classes.footer}>
            <div className={classes.link} onClick={signOut}>
                <IconLogout className={classes.linkIcon} size={20}/>
                <span style={{whiteSpace: 'nowrap'}}>Logout</span>
            </div>
            </Navbar.Section> */}
        </ScrollArea>
      </Navbar>
  );
}