import { useEffect, useState } from 'react';
import {
    useMantineTheme, TextInput, Text, Modal, LoadingOverlay, NumberInput, ActionIcon,Group
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';

import { IconPlus, IconEdit, IconX, IconDeviceFloppy, IconEyeOff, IconArrowBackUp } from '@tabler/icons-react';

export default function MembershipPlanModal({ opened, onClose, onSubmit, onUpdate, onDisable, onEnable, isSuccess, isLoading, data }) {
    const theme = useMantineTheme();

    const [editable, setEditable] = useState({
        id: null,
        name: '',
        price: 0
    })

    const [name, setName] = useState('')

    useEffect(() => {
        if (isSuccess || opened) {
            form.reset()
        }
    }, [isSuccess, opened]);

    const form = useForm({
        initialValues: { name: '', price: 0, },

        validate: {
            name: (value) => (value.length < 1 ? 'Please fill in the empty field' : null),
            // price: (value) => (value.length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
            // role: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
            // confirmPassword: (value, values) =>
            //     value !== values.password ? 'Passwords did not match' : null,
        },
    });

    const onSaveClick = () => {
        if(name.trim().length < 1){
            notifications.show({
                title: 'Information',
                message: 'Name cannot be empty',
                color: 'blue',
            })
            return
        }
        onUpdate({...editable, name})
        setEditable({id: null, name: '', price: 0})
        setName('')
    }

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            // withCloseButton={false}
            // p='xl'
            padding={'xl'}
            style={{ zIndex: 999 }}
            size={'xl'}
            title="Membership Type"
        >
            <LoadingOverlay visible={isLoading} overlayBlur={10} />
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            {data.map((item, i)=>(
                <div key={i} style={{display: 'flex', gap: 10, width: '100%'}}>
                    <div style={{flex: 1}}>
                    <TextInput
                        label={i===0 ? 'Name' : ''}
                        value={editable.id !== item.id ? item.name : name}
                        readOnly={editable.id !== item.id}
                        disabled={editable.id !== item.id}
                        onChange={(event) => setName(event.currentTarget.value)}
                    />
                    </div>
                    <div style={{flex: 1}}>
                    <NumberInput
                        label={i===0 ? 'Price' : ''}
                        precision={2}
                        hideControls
                        readOnly={editable.id !== item.id}
                        disabled={editable.id !== item.id}
                        onChange={(value)=>setEditable((prev) => {return {...prev, price: value}})}
                        value={editable.id !== item.id ? item.price : editable.price}
                    />
                    </div>
                        {editable.id === item.id ? 
                        <Group spacing={10}>
                            <ActionIcon disabled={editable.id !== item.id && editable.id !== null} onClick={onSaveClick} size='lg' variant="outline" color='green' mt={i===0 ? 26 : 0}>
                                <IconDeviceFloppy size={20} />
                            </ActionIcon>
                            <ActionIcon disabled={editable.id !== item.id && editable.id !== null} onClick={()=>{setEditable({id: null, name: '', price: 0});setName('')}} size='lg' variant="outline" color='gray' mt={i===0 ? 26 : 0}>
                                <IconX size={20} />
                            </ActionIcon>
                        </Group>
                        :
                        <Group spacing={10}>
                        <ActionIcon disabled={editable.id !== item.id && editable.id !== null} onClick={()=>{setEditable(item);setName(item.name)}} size='lg' variant="outline" color='blue' mt={i===0 ? 26 : 0}>
                            <IconEdit size={20} />
                        </ActionIcon>
                        {item.deleted ? 
                            <ActionIcon disabled={editable.id !== item.id && editable.id !== null} onClick={()=>{onEnable(item)}} size='lg' variant="outline" color='lime' mt={i===0 ? 26 : 0}>
                                <IconArrowBackUp size={20} />
                            </ActionIcon>
                            :
                            <ActionIcon disabled={editable.id !== item.id && editable.id !== null} onClick={()=>{onDisable(item)}} size='lg' variant="outline" color='red' mt={i===0 ? 26 : 0}>
                                <IconEyeOff size={20} />
                            </ActionIcon>
                        }
                        </Group>
                        }
                </div>
            ))}
            </div>
            {data.length === 0 &&
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',backgroundColor: theme.colorScheme === 'light' ? '#FAFAFA': '#3d3d3d', height: 200}}>
                    <Text>No Membership Type</Text>
                </div>
            }
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                <Text mt={'md'} size={14}>Add Membership Type</Text>
                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                    <div style={{flex: 1}}>
                    <TextInput
                        label="Name"
                        withAsterisk
                        {...form.getInputProps('name')}
                    />
                    </div>
                    <div style={{flex: 1}}>
                    <NumberInput
                        label="Price"
                        precision={2}
                        hideControls
                        withAsterisk
                        {...form.getInputProps('price')}
                    />
                    </div>
                    <ActionIcon type="submit" size='lg' variant="outline" color='green' mt={26}>
                        <IconPlus size={20} />
                    </ActionIcon>
                </div>
            </form>
        </Modal>
    )
}