import { useState } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, TextInput, Grid, Select, SegmentedControl, NumberInput, Loader, ActionIcon, Skeleton
} from '@mantine/core';
import { IconPlus, IconArrowLeft, IconCheck, IconExclamationCircle, IconX } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';
import _ from 'lodash';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function EditCourt() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id, courtId } = useParams()

    // console.log(courtId)

    const [openingHours, setOpeningHours] = useState([])

    const [monday, setMonday] = useState({
        time: null,
        price: 0,
    })
    const [tuesday, setTuesday] = useState({
        time: null,
        price: 0,
    })
    const [wednesday, setWednesday] = useState({
        time: null,
        price: 0,
    })
    const [thursday, setThursday] = useState({
        time: null,
        price: 0,
    })
    const [friday, setFriday] = useState({
        time: null,
        price: 0,
    })
    const [saturday, setSaturday] = useState({
        time: null,
        price: 0,
    })
    const [sunday, setSunday] = useState({
        time: null,
        price: 0,
    })


    const form = useForm({
        initialValues: {
            name: '',
            sport: null,
            device_id: '',

            priceType: '1',
            prices: '',
            price: 0,

            monday_pricing: [],
            tuesday_pricing: [],
            wednesday_pricing: [],
            thursday_pricing: [],
            friday_pricing: [],
            saturday_pricing: [],
            sunday_pricing: [],

        },

        validate: {
            name: (value) => (value.trim()?.length < 1 ? 'Please fill in the empty field' : null),
            sport: (value) => (value === null ? 'Please fill in the empty field' : null),

            monday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Monday' : null),
            tuesday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Tuesday' : null),
            wednesday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Wednesday' : null),
            thursday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Thursday' : null),
            friday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Friday' : null),
            saturday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Saturday' : null),
            sunday_pricing: (value, values) => (value?.length === 0 && values.priceType === '2' ? 'Please add time and price for Sunday' : null),
        },
    });

    const timePriceToObject = (arr) => {
        return _.reduce(
            arr,
            (acc, { time, price }) => ({ ...acc, [time < 10 ? `0${time}:00` : `${time}:00`]: price.toString() }),
            {}
        )
    }

    const editCourt = (data) => {

        let pricing = data.price
        if(data.priceType === '2'){
            let pricing0 = timePriceToObject(data.monday_pricing)
            let pricing1 = timePriceToObject(data.tuesday_pricing)
            let pricing2 = timePriceToObject(data.wednesday_pricing)
            let pricing3 = timePriceToObject(data.thursday_pricing)
            let pricing4 = timePriceToObject(data.friday_pricing)
            let pricing5 = timePriceToObject(data.saturday_pricing)
            let pricing6 = timePriceToObject(data.sunday_pricing)
            
            let pricingObj = [
                {"day":"Monday","price":{...pricing0}},
                {"day":"Tuesday","price":{...pricing1}},
                {"day":"Wednesday","price":{...pricing2}},
                {"day":"Thursday","price":{...pricing3}},
                {"day":"Friday","price":{...pricing4}},
                {"day":"Saturday","price":{...pricing5}},
                {"day":"Sunday","price":{...pricing6}}
            ]

            pricing = JSON.stringify(pricingObj)
        }
        // return
        const formData = new FormData();

        formData.append('courtId', courtId);
        formData.append('name', data?.name.trim())
        formData.append('type', data?.sport)
        formData.append('price', pricing)
        formData.append('priceType', data?.priceType)
        formData.append('device_id', data?.device_id.trim())

        notifications.show({
            id: 'update-court',
            loading: true,
            title: 'Updating court',
            message: 'The court is being updated.',
            autoClose: false,
        })

        updateCourtMutation.mutate(formData)
    }

    const getSportSelectionQuery = useQuery(
        "getSportSelection",
        async ({ signal }) => (await axiosPrivate.get("/sports/get-all-selection", { }, { signal })).data.sports,
        {
            initialData: [],
            onSuccess: (res) => {
                // setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getLocationQuery = useQuery(
        "getLocation",
        async ({ signal }) => (await axiosPrivate.post("/crm/locations/read-by-location-id", { locationId: id }, { signal })).data.location,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res.opening_hours)
                let resOpeningHours = JSON.parse(res.opening_hours ? res.opening_hours : '[]')
                setOpeningHours(resOpeningHours);
                // setSelectedSport(res[0].value)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getCourtQuery = useQuery(
        "getCourt",
        async ({ signal }) => (await axiosPrivate.post("/crm/courts/read-by-uid", { courtId }, { signal })).data.courts,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
                form.setValues((prev) => ({ ...prev, name: res.name, sport: res.type, device_id: res.device_id, priceType: `${res.priceType}` }))
                if(res.priceType === 1){
                    form.setValues((prev) => ({ ...prev, price: parseFloat(res.price), }))
                }
                else{
                    let pricing = JSON.parse(res.price)
                    let price0 = Object.entries(pricing[0].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price1 = Object.entries(pricing[1].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price2 = Object.entries(pricing[2].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price3 = Object.entries(pricing[3].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price4 = Object.entries(pricing[4].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price5 = Object.entries(pricing[5].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    let price6 = Object.entries(pricing[6].price).map(([key, value]) => ({ time: parseInt(key), price: parseFloat(value) }));
                    form.setValues((prev) => ({ ...prev, monday_pricing: price0, tuesday_pricing: price1, wednesday_pricing: price2, thursday_pricing: price3, friday_pricing: price4, saturday_pricing: price5, sunday_pricing: price6 }))
                }

            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const updateCourtMutation = useMutation(
        (data) => axiosPrivate.post(`crm/courts/update`, data, { headers: { 'Content-Type': 'multipart/form-data' } }),
        {
            onSuccess: (res) => {
                notifications.update({
                    id: 'update-court',
                    color: 'teal',
                    title: 'Court updated',
                    message: 'The court is successfully updated.',
                    icon: <IconCheck size={16} />,
                    autoClose: 4000,
                });
                queryClient.invalidateQueries("courts")
                goBack()
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.update({
                    id: 'update-court',
                    color: 'red',
                    title: 'Error',
                    message: errMsg ? errMsg : 'An error has occurred.',
                    icon: <IconExclamationCircle size={16} />,
                    autoClose: 4000,
                });
            }
        }
    );

    const addMondayPrice = () => {
        if(form.values.monday_pricing?.length === 0){
            form.setFieldValue('monday_pricing', [{time: openingHours[0].open, price: monday.price}])
            setMonday({time: null, price: 0})
        }
        else{
            if(monday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('monday_pricing', [...form.values.monday_pricing, {time: monday.time, price: monday.price}])
            setMonday({time: null, price: 0})
        }
    }
    const removeMondayPrice = (index) => {
        const copy = [...form.values.monday_pricing];
        copy.splice(index)
        form.setFieldValue('monday_pricing', copy)
    }

    const addTuesdayPrice = () => {
        if(form.values.tuesday_pricing?.length === 0){
            form.setFieldValue('tuesday_pricing', [{time: openingHours[1].open, price: tuesday.price}])
            setTuesday({time: null, price: 0})
        }
        else{
            if(tuesday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('tuesday_pricing', [...form.values.tuesday_pricing, {time: tuesday.time, price: tuesday.price}])
            setTuesday({time: null, price: 0})
        }
    }
    const removeTuesdayPrice = (index) => {
        const copy = [...form.values.tuesday_pricing];
        copy.splice(index)
        form.setFieldValue('tuesday_pricing', copy)
    }

    const addWednesdayPrice = () => {
        if(form.values.wednesday_pricing?.length === 0){
            form.setFieldValue('wednesday_pricing', [{time: openingHours[2].open, price: wednesday.price}])
            setWednesday({time: null, price: 0})
        }
        else{
            if(wednesday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('wednesday_pricing', [...form.values.wednesday_pricing, {time: wednesday.time, price: wednesday.price}])
            setWednesday({time: null, price: 0})
        }
    }
    const removeWednesdayPrice = (index) => {
        const copy = [...form.values.wednesday_pricing];
        copy.splice(index)
        form.setFieldValue('wednesday_pricing', copy)
    }

    const addThursdayPrice = () => {
        if(form.values.thursday_pricing?.length === 0){
            form.setFieldValue('thursday_pricing', [{time: openingHours[3].open, price: thursday.price}])
            setThursday({time: null, price: 0})
        }
        else{
            if(thursday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('thursday_pricing', [...form.values.thursday_pricing, {time: thursday.time, price: thursday.price}])
            setThursday({time: null, price: 0})
        }
    }
    const removeThursdayPrice = (index) => {
        const copy = [...form.values.thursday_pricing];
        copy.splice(index)
        form.setFieldValue('thursday_pricing', copy)
    }

    const addFridayPrice = () => {
        if(form.values.friday_pricing?.length === 0){
            form.setFieldValue('friday_pricing', [{time: openingHours[4].open, price: friday.price}])
            setFriday({time: null, price: 0})
        }
        else{
            if(friday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('friday_pricing', [...form.values.friday_pricing, {time: friday.time, price: friday.price}])
            setFriday({time: null, price: 0})
        }
    }
    const removeFridayPrice = (index) => {
        const copy = [...form.values.friday_pricing];
        copy.splice(index)
        form.setFieldValue('friday_pricing', copy)
    }

    const addSaturdayPrice = () => {
        if(form.values.saturday_pricing?.length === 0){
            form.setFieldValue('saturday_pricing', [{time: openingHours[5].open, price: saturday.price}])
            setSaturday({time: null, price: 0})
        }
        else{
            if(saturday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('saturday_pricing', [...form.values.saturday_pricing, {time: saturday.time, price: saturday.price}])
            setSaturday({time: null, price: 0})
        }
    }
    const removeSaturdayPrice = (index) => {
        const copy = [...form.values.saturday_pricing];
        copy.splice(index)
        form.setFieldValue('saturday_pricing', copy)
    }

    const addSundayPrice = () => {
        if(form.values.sunday_pricing?.length === 0){
            form.setFieldValue('sunday_pricing', [{time: openingHours[6].open, price: sunday.price}])
            setSunday({time: null, price: 0})
        }
        else{
            if(sunday.time === null){
                notifications.show({
                    title: 'Information',
                    message: 'Please select time',
                    color: 'blue',
                })
                return;
            }
            form.setFieldValue('sunday_pricing', [...form.values.sunday_pricing, {time: sunday.time, price: sunday.price}])
            setSunday({time: null, price: 0})
        }
    }
    const removeSundayPrice = (index) => {
        const copy = [...form.values.sunday_pricing];
        copy.splice(index)
        form.setFieldValue('sunday_pricing', copy)
    }

    const goBack = () => {
        navigate(`/venue/court/${id}`, { replace: true })
    }

    return (
        <>
            <Group position='left'>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={updateCourtMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Edit Court </Text>
                {getLocationQuery.isFetching || getSportSelectionQuery.isFetching || getCourtQuery.isFetching ?
                    <Loader size={'sm'} variant='dots'/>
                    : null
                }
            </Group>
            <Divider my="md" />
            <Skeleton visible={getLocationQuery.isFetching || getSportSelectionQuery.isFetching || getCourtQuery.isFetching || getLocationQuery.isError || getSportSelectionQuery.isError || getCourtQuery.isError} animate={!getLocationQuery.isError || !getSportSelectionQuery.isError || !getCourtQuery.isError}>
            {/* {getLocationQuery.isSuccess && getSportSelectionQuery.isSuccess && getCourtQuery.isSuccess ? */}
            <form onSubmit={form.onSubmit((values) => { editCourt(values); })}>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Court Name"
                            withAsterisk
                            {...form.getInputProps('name')}
                            disabled={updateCourtMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Select
                            data={getSportSelectionQuery.data}
                            label="Select Sport"
                            placeholder="Select Sport"
                            {...form.getInputProps('sport')}
                            disabled={updateCourtMutation.isLoading}
                            withAsterisk
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                            label="Device ID"
                            // withAsterisk
                            {...form.getInputProps('device_id')}
                            disabled={updateCourtMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6} pb={0}>
                        <Divider my="xs" label="Pricing" labelPosition="center" />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Text fw={500} size={14}>Price Type</Text>
                        <SegmentedControl
                            {...form.getInputProps('priceType')}
                            data={[
                                { label: 'Fixed', value: '1' },
                                { label: 'Variable', value: '2' },
                            ]}
                            color="iCourtOrange"
                            disabled={updateCourtMutation.isLoading}
                        />
                    </Grid.Col>
                </Grid>
                {
                form.values.priceType === '1' ?
                <>
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <NumberInput
                                label="Price"
                                {...form.getInputProps('price')}
                                precision={2}
                                hideControls
                                withAsterisk
                            />
                        </Grid.Col>
                    </Grid>
                </>
                :
                <>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Monday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.monday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeMondayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.monday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[0].open < 10 ? `0${openingHours[0].open}:00` : `${openingHours[0].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.monday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        // {...form.getInputProps('monday_close')}
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setMonday((prev) => {return {...prev, price: value}})}
                                        value={monday.price}
                                        error={form.errors.monday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addMondayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={monday.time}
                                    data={
                                        form.values.monday_pricing.length > 0 ?
                                        [...Array(openingHours[0]?.close-(form.values.monday_pricing[form.values.monday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.monday_pricing[form.values.monday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setMonday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setMonday((prev) => {return {...prev, price: value}})}
                                    value={monday.price}
                                />
                                </div>
                                <ActionIcon onClick={addMondayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Tuesday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.tuesday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeTuesdayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.tuesday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[1].open < 10 ? `0${openingHours[1].open}:00` : `${openingHours[1].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.tuesday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setTuesday((prev) => {return {...prev, price: value}})}
                                        value={tuesday.price}
                                        error={form.errors.tuesday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addTuesdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={tuesday.time}
                                    data={
                                        form.values.tuesday_pricing.length > 0 ?
                                        [...Array(openingHours[1]?.close-(form.values.tuesday_pricing[form.values.tuesday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.tuesday_pricing[form.values.tuesday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setTuesday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setTuesday((prev) => {return {...prev, price: value}})}
                                    value={tuesday.price}
                                />
                                </div>
                                <ActionIcon onClick={addTuesdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Wednesday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.wednesday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeWednesdayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.wednesday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[2].open < 10 ? `0${openingHours[2].open}:00` : `${openingHours[2].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.wednesday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setWednesday((prev) => {return {...prev, price: value}})}
                                        value={wednesday.price}
                                        error={form.errors.wednesday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addWednesdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={wednesday.time}
                                    data={
                                        form.values.wednesday_pricing.length > 0 ?
                                        [...Array(openingHours[2]?.close-(form.values.wednesday_pricing[form.values.wednesday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.wednesday_pricing[form.values.wednesday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setWednesday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setWednesday((prev) => {return {...prev, price: value}})}
                                    value={wednesday.price}
                                />
                                </div>
                                <ActionIcon onClick={addWednesdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Thursday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.thursday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeThursdayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.thursday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[3].open < 10 ? `0${openingHours[3].open}:00` : `${openingHours[3].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.thursday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setThursday((prev) => {return {...prev, price: value}})}
                                        value={thursday.price}
                                        error={form.errors.thursday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addThursdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={thursday.time}
                                    data={
                                        form.values.thursday_pricing.length > 0 ?
                                        [...Array(openingHours[3]?.close-(form.values.thursday_pricing[form.values.thursday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.thursday_pricing[form.values.thursday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setThursday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setThursday((prev) => {return {...prev, price: value}})}
                                    value={thursday.price}
                                />
                                </div>
                                <ActionIcon onClick={addThursdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Friday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.friday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeFridayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.friday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[4].open < 10 ? `0${openingHours[4].open}:00` : `${openingHours[4].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.friday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setFriday((prev) => {return {...prev, price: value}})}
                                        value={friday.price}
                                        error={form.errors.friday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addFridayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={friday.time}
                                    data={
                                        form.values.friday_pricing.length > 0 ?
                                        [...Array(openingHours[4]?.close-(form.values.friday_pricing[form.values.friday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.friday_pricing[form.values.friday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setFriday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setFriday((prev) => {return {...prev, price: value}})}
                                    value={friday.price}
                                />
                                </div>
                                <ActionIcon onClick={addFridayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Saturday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.saturday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeSaturdayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.saturday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[5].open < 10 ? `0${openingHours[5].open}:00` : `${openingHours[5].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.saturday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setSaturday((prev) => {return {...prev, price: value}})}
                                        value={saturday.price}
                                        error={form.errors.saturday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addSaturdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={saturday.time}
                                    data={
                                        form.values.saturday_pricing.length > 0 ?
                                        [...Array(openingHours[5]?.close-(form.values.saturday_pricing[form.values.saturday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.saturday_pricing[form.values.saturday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setSaturday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setSaturday((prev) => {return {...prev, price: value}})}
                                    value={saturday.price}
                                />
                                </div>
                                <ActionIcon onClick={addSaturdayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                    <Grid mb={0} pb={0} mt={'md'}>
                        <Grid.Col sm={8} md={6} lg={6} xl={6} mb={0} pb={0}>
                            <Text fw={500} size={14}>Sunday <span style={{ color: 'red' }}>*</span></Text>
                        </Grid.Col>
                    </Grid>
                    {form?.values?.sunday_pricing?.map((item, i)=>(
                        <Grid key={i}>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        withAsterisk
                                        value={item.time < 10 ? `0${item.time}:00` : `${item.time}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        value={item.price}
                                        readOnly={true}
                                    />
                                    </div>
                                    <ActionIcon onClick={()=>removeSundayPrice(i)} size='lg' variant="outline" color='red'>
                                        <IconX size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    ))}
                    <>
                    {form.values.sunday_pricing?.length === 0 ? 
                        <Grid>
                            <Grid.Col sm={8} md={6} lg={6} xl={6}>
                                <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                    <div style={{flex: 1}}>
                                    <TextInput
                                        label="Time"
                                        withAsterisk
                                        value={openingHours[6].open < 10 ? `0${openingHours[6].open}:00` : `${openingHours[6].open}:00`}
                                        disabled={updateCourtMutation.isLoading}
                                        readOnly={true}
                                        error={form.errors.sunday_pricing}
                                    />
                                    </div>
                                    <div style={{flex: 1}}>
                                    <NumberInput
                                        label="Price"
                                        precision={2}
                                        hideControls
                                        withAsterisk
                                        onChange={(value)=>setSunday((prev) => {return {...prev, price: value}})}
                                        value={sunday.price}
                                        error={form.errors.sunday_pricing}
                                    />
                                    </div>
                                    <ActionIcon onClick={addSundayPrice} size='lg' variant="outline" color='green' mt={26}>
                                        <IconPlus size={20} />
                                    </ActionIcon>
                                </div>
                            </Grid.Col>
                        </Grid>
                    : 
                    <Grid>
                        <Grid.Col sm={8} md={6} lg={6} xl={6}>
                            <div style={{display: 'flex', gap: 10, width: '100%'}}>
                                <div style={{flex: 1}}>
                                <Select
                                    label="Time"
                                    placeholder="Pick one"
                                    withAsterisk
                                    value={sunday.time}
                                    data={
                                        form.values.sunday_pricing.length > 0 ?
                                        [...Array(openingHours[6]?.close-(form.values.sunday_pricing[form.values.sunday_pricing?.length-1].time+1))].map((x,i)=>{let open = (form.values.sunday_pricing[form.values.sunday_pricing?.length-1].time+1); return{value: i+open, label: i+open < 10 ? `0${i+open}:00` : `${i+open}:00`}})
                                        : []
                                    }
                                    disabled={updateCourtMutation.isLoading}
                                    onChange={(value)=>setSunday((prev) => {return {...prev, time: value}})}
                                />
                                </div>
                                <div style={{flex: 1}}>
                                <NumberInput
                                    label="Price"
                                    precision={2}
                                    hideControls
                                    withAsterisk
                                    onChange={(value)=>setSunday((prev) => {return {...prev, price: value}})}
                                    value={sunday.price}
                                />
                                </div>
                                <ActionIcon onClick={addSundayPrice} size='lg' variant="outline" color='green' mt={26}>
                                    <IconPlus size={20} />
                                </ActionIcon>
                            </div>
                        </Grid.Col>
                    </Grid>
                    }
                    </>
                </>}
                <Grid my='xl'>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button color="gray" fullWidth onClick={goBack} disabled={updateCourtMutation.isLoading}>
                            Back
                        </Button>
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button type="submit" fullWidth loading={updateCourtMutation.isLoading}>
                            Submit
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
            {/* : null } */}
            </Skeleton>
        </>
    );
}
