import axios from "../api/axios"
import useAuth from "./useAuth"

import { SUPER_ADMIN, LOCATION_ADMIN, LOCATION_STAFF } from "../utils/Constants"

 export default function useRefreshToken() {
    const { setAuth } = useAuth()

    const refresh = async () => {
        try{
            const res = await axios.post('/crm/auth/generate-token', {withCredentials: true});
            
            const accessToken = res?.data?.token;
            const userDetails = res?.data?.userDetails;
            setAuth({ fullname: userDetails.fullname, admin_id: userDetails.admin_id, accessToken, locationId : userDetails.locationId, role: userDetails.locationId === '1' ? SUPER_ADMIN : userDetails.isStaff === true ? LOCATION_STAFF : LOCATION_ADMIN })
            
            return accessToken
        }
        catch(error){
            setAuth(null)
            return null;
        }
    }
   return refresh
 }
 