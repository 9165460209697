import { forwardRef } from 'react';
import {
    Text, Divider, Button, Group, useMantineTheme, Grid, Select, Loader, Skeleton, TextInput
} from '@mantine/core';
import { IconArrowLeft, IconCheck, IconExclamationCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from '@mantine/form';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';


  const SelectItem2 = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <div>
            <Text size="sm">{label}</Text>
            <Text size="xs" opacity={0.65}>
              Discount RM{description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

export default function EditMember() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme();
    let navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id, membershipId } = useParams()

    const form = useForm({
        initialValues: {
            type: null,
        },

        validate: {
            type: (value) => (value === null ? 'Please fill in the empty field' : null),
        },
    });


    const getMembershipQuery = useQuery(
        "getMembership",
        async ({ signal }) => (await axiosPrivate.post("/crm/membership/read-by-membership-id", { membershipId }, { signal })).data.membership,
        {
            initialData: [],
            onSuccess: (res) => {
                form.setFieldValue('type', res.type)
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getTypeSelectionQuery = useQuery(
        "getTypeSelection",
        async ({ signal }) => (await axiosPrivate.post("/crm/membership/get-membership-type-selection", { locationId: id }, { signal })).data.membershipType,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log('Membership Type')
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getLocationQuery = useQuery(
        "getLocation",
        async ({ signal }) => (await axiosPrivate.post("/crm/locations/read-by-location-id", { locationId: id }, { signal })).data.location,
        {
            initialData: [],
            onSuccess: (res) => {
            },
            onError: (err) => {
                let errMsg = err?.response?.data?.message
                notifications.show({
                    title: 'Error',
                    message: errMsg ? errMsg : 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const updateMemberMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership/update`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'update-member',
                color: 'teal',
                title: `Member updated`,
                message: `The member is successfully updated.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("membership")
            queryClient.invalidateQueries("getUserSelection")
            goBack()
        },
        onError: () => {
            notifications.update({
                id: 'update-member',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const addMember = (data) => {
        const formData = new FormData();

        formData.append('membershipId', membershipId);
        // formData.append('locationId', id);
        formData.append('type', data.type);

        notifications.show({
            id: 'update-member',
            loading: true,
            title: `Updating member`,
            message: `The member is being updated.`,
            autoClose: false,
        })
        updateMemberMutation.mutate(formData)
    }


    const goBack = () => {
        navigate(`/venue/membership/${id}`, { replace: true })
    }

    return (
        <>
            <Group position='left'>
                <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={updateMemberMutation.isLoading} size='xs'>
                    Back
                </Button>
                <Text size={'xl'} weight={600}> Edit Member </Text>
                {getLocationQuery.isFetching ?
                    <Loader size={'sm'} variant='dots'/>
                    : null
                }
            </Group>
            <Divider my="md" />
            <Skeleton visible={getLocationQuery.isFetching || getMembershipQuery.isFetching || getLocationQuery.isError || getMembershipQuery.isError} animate={!getLocationQuery.isError || !getMembershipQuery.isError}>
            <form onSubmit={form.onSubmit((values) => { addMember(values); })}>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <TextInput
                        label={getMembershipQuery.data ? getMembershipQuery.data.fullname : 'User'}
                        value={getMembershipQuery.data ? getMembershipQuery.data.phone_number : 'Loading'}
                        icon={<Text size={14}>+60</Text>}
                        readOnly
                        />
                    </Grid.Col>
                </Grid>
                <Grid>
                    <Grid.Col sm={8} md={6} lg={6} xl={6}>
                        <Select
                            label="Select Membership Type"
                            placeholder="Search membership type"
                            searchable
                            nothingFound="Membership type not found"
                            itemComponent={SelectItem2}
                            data={getTypeSelectionQuery.data}
                            {...form.getInputProps('type')}
                            disabled={updateMemberMutation.isLoading}
                            filter={(value, item) =>
                                item.label.toLowerCase().includes(value.toLowerCase().trim())
                            }
                        />
                    </Grid.Col>
                </Grid>

                <Grid my='xl'>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button color="gray" fullWidth onClick={goBack} disabled={updateMemberMutation.isLoading}>
                            Back
                        </Button>
                    </Grid.Col>
                    <Grid.Col sm={4} md={3} lg={3} xl={3} pb={0}>
                        <Button type="submit" fullWidth loading={updateMemberMutation.isLoading}>
                            Submit
                        </Button>
                    </Grid.Col>
                </Grid>
            </form>
            </Skeleton>
        </>
    );
}
