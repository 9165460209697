import { useState, } from 'react';
import {
  Text, Divider, Button, Group, useMantineTheme,
} from '@mantine/core';
import {
  DataGrid,
} from 'mantine-data-grid';
import { IconCirclePlus, IconPencil, IconArrowLeft, IconCheck, IconExclamationCircle, IconArrowBack, IconEyeOff, IconLayoutGrid } from '@tabler/icons-react';
import { notifications, } from '@mantine/notifications';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import ConfirmationModal from '../../components/general/ConfirmationModal';
import MembershipPlanModal from '../../components/membership/MembershipPlanModal';

import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

export default function LAManageMembership() {
    const axiosPrivate = useAxiosPrivate();
    const theme = useMantineTheme()
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // const { id } = useParams()
    const { auth } = useAuth()
    const id = auth?.locationId

    const [actionId, setActionId] = useState('');

    const [openCModal, setOpenCModal] = useState(false); //delete
    const [openRCModal, setOpenRCModal] = useState(false); //restore

    const [openMPModal, setOpenMPModal] = useState(false);

    const { isFetching, data: membership } = useQuery(
        "membership",
        async ({ signal }) => (await axiosPrivate.post(`crm/membership/read-by-location-id`, {locationId: id}, { signal })).data.membership,
        {
        initialData: [],
        onSuccess: (res) => {
            // console.log(res)
        },
        onError: (err) => {
            let errMsg = err?.response?.data?.message
            notifications.show({
                title: 'Error',
                message: errMsg ? errMsg : 'Something went wrong while connecting to the server',
                color: 'red',
            })
        }
        }
    );

    const getLocationQuery = useQuery(
        "getLocation",
        async ({ signal }) => (await axiosPrivate.post("/crm/locations/read-by-location-id", { locationId: id }, { signal })).data.location,
        {
            initialData: [],
            onSuccess: (res) => {
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const getMembershipTypeQuery = useQuery(
        "getMembershipType",
        async ({ signal }) => (await axiosPrivate.post("/crm/membership-type/read-by-location-id", { locationId: id }, { signal })).data.membershipType,
        {
            initialData: [],
            onSuccess: (res) => {
                // console.log(res)
            },
            onError: () => {
                notifications.show({
                    title: 'Error',
                    message: 'Unable to load...',
                    color: 'red',
                })
            },
        }
    );

    const deactivateMembershipMutation = useMutation(
        (membershipId) => axiosPrivate.post(`crm/membership/activate-deactivate`, { membershipId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'deactivate-member',
                color: 'teal',
                title: `Membership deactivated`,
                message: `The membership is successfully deactivated.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("membership")
        },
        onError: () => {
            notifications.update({
                id: 'deactivate-member',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const activateMembershipMutation = useMutation(
        (membershipId) => axiosPrivate.post(`crm/membership/activate-deactivate`, { membershipId }),
        {
        onSuccess: () => {
            notifications.update({
                id: 'activate-member',
                color: 'teal',
                title: `Membership activated`,
                message: `The membersip is successfully activated.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("membership")
        },
        onError: () => {
            notifications.update({
                id: 'activate-member',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const addTypeMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership-type/add`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'add-membership-type',
                color: 'teal',
                title: `Membership type added`,
                message: `The membersip type is successfully added.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("getMembershipType")
            queryClient.invalidateQueries("getTypeSelection")
        },
        onError: () => {
            notifications.update({
                id: 'add-membership-type',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const updateTypeMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership-type/update`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'update-membership-type',
                color: 'teal',
                title: `Membership type updated`,
                message: `The membership type is successfully updated.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("getMembershipType")
            queryClient.invalidateQueries("getTypeSelection")
        },
        onError: () => {
            notifications.update({
                id: 'update-membership-type',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const disableMembershipTypeMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership-type/enable-disable`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'disable-membership-type',
                color: 'teal',
                title: `Membership type disabled`,
                message: `The membership is successfully disabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("getMembershipType")
            queryClient.invalidateQueries("getTypeSelection")
        },
        onError: () => {
            notifications.update({
                id: 'disable-membership-type',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const enableMembershipTypeMutation = useMutation(
        (data) => axiosPrivate.post(`crm/membership-type/enable-disable`, data),
        {
        onSuccess: () => {
            notifications.update({
                id: 'enable-membership-type',
                color: 'teal',
                title: `Membership type enabled`,
                message: `The membership is successfully enabled.`,
                icon: <IconCheck size={16} />,
                autoClose: 4000,
            });
            queryClient.invalidateQueries("getMembershipType")
            queryClient.invalidateQueries("getTypeSelection")
        },
        onError: () => {
            notifications.update({
                id: 'disable-membership-type',
                color: 'red',
                title: 'Error',
                message: 'An error has occurred.',
                icon: <IconExclamationCircle size={16} />,
                autoClose: 4000,
            });
        }
        }
    );

    const deactivateMembership = () => {
        setOpenCModal(false);
        notifications.show({
            id: 'deactivate-member',
            loading: true,
            title: `Membership deactivating`,
            message: `The membership is being deactivated.`,
            autoClose: false,
        })
        deactivateMembershipMutation.mutate(actionId)
    }

    const activateMembership = () => {
        setOpenRCModal(false);
        notifications.show({
            id: 'activate-member',
            loading: true,
            title: `Membership activating`,
            message: `The membership is being activated.`,
            autoClose: false,
        })
        activateMembershipMutation.mutate(actionId)
    }


    const onClickCreateMember= () => {
        navigate(`/membership/add`);
    }

    const onClickMembershipPlan = () => {
        setOpenMPModal(true)
    }


    const addMembershipType = (data) => {
        notifications.show({
            id: 'add-membership-type',
            loading: true,
            title: 'Membership type adding',
            message: 'The membership type is being added.',
            autoClose: false,
        })

        const formData = new FormData();

        formData.append('locationId', id);
        formData.append('name', data.name.trim());
        formData.append('price', data.price);

        addTypeMutation.mutate(formData)
    }

    const  updateMembershipType = (data) => {
        notifications.show({
            id: 'update-membership-type',
            loading: true,
            title: 'Membership type updating',
            message: 'The membership type is being updated.',
            autoClose: false,
        })

        const formData = new FormData();

        formData.append('locationId', id);
        formData.append('id', data.id);
        formData.append('name', data.name.trim());
        formData.append('price', data.price);

        updateTypeMutation.mutate(formData)
    } 

    const disableMembershipType = (data) => {
        const formData = new FormData();
        formData.append('locationId', id);
        formData.append('id', data.id);

        notifications.show({
            id: 'disable-membership-type',
            loading: true,
            title: `Membership type disabling`,
            message: `The membership type is being disabled.`,
            autoClose: false,
        })
        disableMembershipTypeMutation.mutate(formData)
    }

    const enableMembershipType = (data) => {
        const formData = new FormData();
        formData.append('locationId', id);
        formData.append('id', data.id);

        notifications.show({
            id: 'enable-membership-type',
            loading: true,
            title: `Membership type enabling`,
            message: `The membership type is being activated.`,
            autoClose: false,
        })
        enableMembershipTypeMutation.mutate(formData)
    }

//   const onClickCreateMultipleCourt = () => {
//     navigate(`/venue/court/add-multiple/${id}`);
//   }

  const goBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <>
      <ConfirmationModal opened={openCModal} onClose={() => setOpenCModal(false)} text="Are you sure you want to deactivate it?" onClick={deactivateMembership} />
      <ConfirmationModal opened={openRCModal} onClose={() => setOpenRCModal(false)} text="Are you sure you want to activate it?" onClick={activateMembership} />

      <MembershipPlanModal opened={openMPModal} onClose={() => setOpenMPModal(false)} onSubmit={addMembershipType} onUpdate={updateMembershipType} onDisable={disableMembershipType} onEnable={enableMembershipType} data={getMembershipTypeQuery.data}
            isLoading={updateTypeMutation.isLoading || addTypeMutation.isLoading || disableMembershipTypeMutation.isLoading || enableMembershipTypeMutation.isLoading || getLocationQuery.isFetching || getMembershipTypeQuery.isFetching} isSuccess={addTypeMutation.isSuccess || updateTypeMutation.isSuccess|| disableMembershipTypeMutation.isSuccess || enableMembershipTypeMutation.isSuccess} isError={addTypeMutation.isError || updateTypeMutation.isError|| disableMembershipTypeMutation.isError || enableMembershipTypeMutation.isError} />
      
      <Group position='apart'>
            <Group>
                {/* <Button onClick={goBack} leftIcon={<IconArrowLeft size={22} />} variant="subtle" color={theme.colorScheme === 'dark' ? 'gray.5' : 'gray.7'} disabled={deactivateMembershipMutation.isLoading || activateMembershipMutation.isLoading} size='xs'>
                    Back
                </Button> */}
                <Text size={'xl'} weight={600}> Manage Membership </Text>
            </Group>
            <Group>
                <Button onClick={onClickMembershipPlan} leftIcon={<IconLayoutGrid size={18} />} size='xs'>
                    Membership Type
                </Button>
                <Button onClick={onClickCreateMember} leftIcon={<IconCirclePlus size={18} />} color="green" size='xs'>
                    Add Member
                </Button>
            </Group>
      </Group>
      <Divider my="md" />
      <DataGrid
        data={membership}
        loading={isFetching}
        striped
        highlightOnHover
        withGlobalFilter
        withPagination
        //withColumnFilters
        withSorting
        //withColumnResizing
        columns={[
          {
            header: 'No.',
            cell: (cell) => {
              return <Text>{cell.row.index + 1}</Text>;
            },
            size: 60,
          },
          {
            accessorKey: 'fullname',
            header: 'Name',
            size: 135,
          },
          {
            accessorKey: 'membershipType',
            header: 'Type',
            size: 135,
          },
          {
            header: 'Action',
            cell: (cell) => {
              return <Group position="center">
                {cell.row.original.deactivated ?
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/membership/edit/${cell.row.original.membershipId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.membershipId); setOpenRCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconArrowBack size={18} />} size="xs" color="lime">Activate</Button>
                  </>
                  :
                  <>
                    <Button variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconPencil size={18} />} size="xs" color="yellow" component={Link} to={`/membership/edit/${cell.row.original.membershipId}`}>Edit</Button>
                    <Button onClick={() => { setActionId(cell.row.original.membershipId); setOpenCModal(true); }} variant={theme.colorScheme === 'dark' ? 'light' : 'outline'} leftIcon={<IconEyeOff size={18} />} size="xs" color="red">Deactivate</Button>
                  </>
                }
              </Group>;
            },
            enableColumnFilter: false,
            enableSorting: false,
            enableGlobalFilter: false,
            size: 280,
          },
        ]}
      />
    </>
  );
}
