import { useEffect, useRef } from 'react';
import {
    useMantineTheme, Button, TextInput, Center, Text, Modal, Stack, Flex
} from '@mantine/core';
import { useForm } from '@mantine/form';


export default function EditStaffModal({ opened, onClose, data, onUpdate }) {
    const theme = useMantineTheme();

    useEffect(() => {
        if (data != null) {
            form.setValues({ fullname: data.fullname, email: data.email, role: data.role });
        }
    }, [data]);

    const form = useForm({
        initialValues: { fullname: '', email: '' },

        validate: {
            fullname: (value) => (value.length < 1 ? 'Please choose a notification type' : null),
            email: (value) => (value.length < 1 ? 'Please fill in the empty field' : /^\S+@\S+$/.test(value) ? null : 'Invalid Email Address'),
            // role: (value) => (value.length < 1 ? 'Please field in the empty field' : null),
        },
    });

    return (
        <Modal
            centered
            opened={opened}
            onClose={onClose}
            withCloseButton={false}
            // p='xl'
            style={{ zIndex: 999 }}
        >
            <Center>
                <Text size='xl' weight={700} color={theme.colors.gray[6]} >Edit Staff Details</Text>
            </Center>
            <form onSubmit={form.onSubmit((values) => onUpdate({ admin_id: data.admin_id, ...values }))}>
                <Stack p='md'>
                    <TextInput
                        label="Full Name"
                        {...form.getInputProps('fullname')}
                    />
                    <TextInput
                        label="Email"
                        {...form.getInputProps('email')}
                    />
                    {/* <TextInput
                        label="Role"
                        {...form.getInputProps('role')}
                        readOnly
                    /> */}
                </Stack>
                <Flex justify="flex-start"
                    align="center"
                    direction="row"
                    wrap="wrap"
                    gap="md" p='md'>
                    <Button type="submit" sx={{ flex: 1 }}>
                        Update
                    </Button>
                    <Button color='gray' sx={{ flex: 1 }} onClick={() => { onClose() }}>
                        Cancel
                    </Button>
                </Flex>
            </form>
        </Modal>
    )
}